import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectInterviewRedirect = (state) => state?.interviewRoomsRedirect || initialState;
const makeInterviewRedirect = () => createSelector(selectInterviewRedirect, (subState) => subState);
const selectSignalDataList = (state) => state?.interviewRoomsRedirect?.signalDataList || [];
const videoServiceProviderState = createSelector(
  selectInterviewRedirect,
  (subState) => subState?.roomDetails?.videoServiceProvider,
);
const participantDataState = createSelector(selectInterviewRedirect, (subState) => subState?.uniqueParticipantsData);
const candidateDetailsState = createSelector(selectInterviewRedirect, (subState) => subState?.candidateDetails);
export const voipStatusDataState = createSelector(selectInterviewRedirect, (subState) => subState?.voipStatusData);
export default selectInterviewRedirect;
export {
  makeInterviewRedirect,
  selectSignalDataList,
  candidateDetailsState,
  videoServiceProviderState,
  participantDataState,
};
