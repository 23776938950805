import { useEffect } from 'react';

/*
 * If a user has published an track from an external input device and
 * disconnects the device, the published track will be stopped and the user
 * will no longer be heard by other participants.
 *
 * To prevent this issue, this hook will re-acquire a mediaStreamTrack from the system's
 * default device when it detects that the published device has been disconnected.
 */

export default function useRestartTrackOnDeviceChange(localTracks = []) {
  const audioTrack = localTracks.find((track) => track && track.kind === 'audio');
  const videoTrack = localTracks.find((track) => track && track.kind === 'video');

  const restartTrack = async (track) => {
    if (track?.mediaStreamTrack.readyState === 'ended') {
      const isTrackMuted = track?.isMuted;
      await track.restart({});
      // Happens with livekit track gets muted after restart
      if (!isTrackMuted && track.unmute) track?.unmute();
    }
  };

  useEffect(() => {
    const handleDeviceChange = () => {
      restartTrack(audioTrack);
      restartTrack(videoTrack);
    };

    navigator.mediaDevices?.addEventListener('devicechange', handleDeviceChange);

    return () => {
      navigator.mediaDevices?.removeEventListener('devicechange', handleDeviceChange);
    };
  }, [audioTrack, videoTrack]);
}
