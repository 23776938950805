/* eslint-disable no-unused-expressions */

import React from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { Avatar } from 'components/base/Avatar';

function CountDownTimer(props) {
  const { eventTime, setViewTimer } = props;
  const [currentTime, updateCurrentTime] = React.useState(DateTime.now());
  const [timeDiff, updateTimeDiff] = React.useState({});

  const eventStartTime = DateTime.fromISO(eventTime);

  let updateCountTime;

  React.useEffect(() => {
    updateCountTime = setInterval(() => {
      updateCurrentTime(DateTime.now());
    }, 1000);
    return () => {
      clearInterval(updateCountTime);
    };
  }, []);

  React.useEffect(() => {
    const formattedTime = {};
    if (eventStartTime) {
      const updatedTime = eventStartTime.diff(currentTime, ['days', 'hours', 'minutes', 'seconds']).toObject();
      if (eventStartTime < currentTime) {
        clearInterval(updateCountTime);
        setViewTimer(false);
      } else {
        if (updatedTime?.hours === 1) {
          formattedTime.hour = updatedTime.hours;
        } else {
          formattedTime.hours = updatedTime.hours;
        }
        if (updatedTime?.minutes === 1) {
          formattedTime.minute = updatedTime.minutes;
        } else {
          formattedTime.minutes = updatedTime.minutes;
        }

        if (updatedTime?.seconds < 2) {
          formattedTime.second = updatedTime.seconds;
        } else {
          formattedTime.seconds = updatedTime.seconds;
        }

        updateTimeDiff(formattedTime);
      }
    }
  }, [currentTime]);

  const getTimeValue = (time) => Math.floor(time).toString();

  return (
    <div className="flex">
      {Object.entries(timeDiff).map((time, index) => (
        <div key={time[0]} className="text-center overflow-hidden rounded-2xl">
          <p className="flex truncate text-6xl font-bold dark:text-blue-500 text-blue-50">
            {index > 0 && <span className="rounded-full flex items-center h-12">:</span>}
            {getTimeValue(time[1])?.length === 1 ? `0${getTimeValue(time[1])}` : getTimeValue(time[1])}
          </p>
        </div>
      ))}
    </div>
  );
}

Avatar.propTypes = {
  eventTime: PropTypes.string,
};

export default CountDownTimer;
