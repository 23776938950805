import {
  UPDATE_PARTICIPANT_STATUS,
  UPDATE_RECORDING_STATUS,
  FETCH_FACE_AUTH_DETAILS,
  CREATE_CANDIDATE_PROFILE,
  UPDATE_VIDEO_REQUEST_STATUS,
  CREATE_AUTH_REQUEST,
  CLOSE_SUBSCRIPTION_CHANNELS,
  UPDATE_RESUME_FULLSCREEN_STATUS,
  ADD_IR_EVENT_LOG,
  UPLOAD_FILE,
  DOWNLOAD_FILE,
  UPDATE_FILES_DOWNLOAD_STATE,
  ADD_CHAT_INPUT_VAL_TO_MODAL,
  UPDATE_MODALS_OPEN_STATE,
  GET_REGISTERED_USER,
  ADD_MEETING_ATTENDEE,
  UPDATE_FULL_VIEW_TABS_OPEN_STATE,
  UPDATE_SIDE_TABS_OPEN_STATE,
  SUBSCRIBE_PARTICIPANTS_DATA,
  VOIP_CALL_MODAL_STATE,
  GET_VOIP_TOKEN,
  STORE_VOIP_CALL_DETAILS,
  FETCH_SEC_CAM_ENV_CHECK_REDIRECTION_URL,
  SEC_CAM_ENV_CHECK_REQUEST_TYPE,
} from './constants';

export function updateParticipantStatusAction(status) {
  return {
    type: UPDATE_PARTICIPANT_STATUS,
    status,
  };
}

export function updateRecordingStatusAction(roomId, status) {
  return {
    type: UPDATE_RECORDING_STATUS,
    roomId,
    recordingStatus: status,
  };
}

export function fetchFaceAuthDetailsAction(candidateId) {
  return {
    type: FETCH_FACE_AUTH_DETAILS,
    candidateId,
  };
}

export function updateFullViewTabsOpenAction(fullViewTabsOpenState) {
  return {
    type: UPDATE_FULL_VIEW_TABS_OPEN_STATE,
    fullViewTabsOpenState,
  };
}

export function updateSideTabsOpenAction(sideTabsOpenState) {
  return {
    type: UPDATE_SIDE_TABS_OPEN_STATE,
    sideTabsOpenState,
  };
}

export function createCandidateProfileAction({ candidate_email, org_id, candidate_old_id }) {
  return {
    type: CREATE_CANDIDATE_PROFILE,
    candidate_email,
    org_id,
    candidate_old_id,
  };
}

export function createAuthRequestAction({ candidate_id, file, file_name, description }) {
  return {
    type: CREATE_AUTH_REQUEST,
    candidate_id,
    file,
    file_name,
    description,
  };
}

export function addIrEventLogAction(name) {
  return {
    type: ADD_IR_EVENT_LOG,
    name,
  };
}

export function updateVideoRequestStatusAction(requestStatus) {
  return {
    type: UPDATE_VIDEO_REQUEST_STATUS,
    requestStatus,
  };
}

export function closeSubscriptionsAction() {
  return {
    type: CLOSE_SUBSCRIPTION_CHANNELS,
  };
}

export function resumeFullscreenStatusAction(isResumeFullScreenOn) {
  return {
    type: UPDATE_RESUME_FULLSCREEN_STATUS,
    isResumeFullScreenOn,
  };
}

export function uploadFileAction({ file, fileName, fileType, fileSize, handleUpload, uploadError, uploadStatus }) {
  return {
    type: UPLOAD_FILE,
    file,
    fileName,
    fileType,
    fileSize,
    uploadToFirebase: handleUpload,
    uploadError,
    uploadStatus,
  };
}

export function downloadFile(fileId, fileName) {
  return {
    type: DOWNLOAD_FILE,
    fileId,
    fileName,
  };
}

export function updateFilesDownloadStateAction(filesDownloadState) {
  return {
    type: UPDATE_FILES_DOWNLOAD_STATE,
    filesDownloadState,
  };
}

export function addChatInputValToModalAction(chatInputValues) {
  return {
    type: ADD_CHAT_INPUT_VAL_TO_MODAL,
    chatInputValues,
  };
}

export function updateModalsOpenStateAction(modalsOpenState) {
  return {
    type: UPDATE_MODALS_OPEN_STATE,
    modalsOpenState,
  };
}

export function updateVoipCallModalStateAction(isVoipCallModalOpen) {
  return {
    type: VOIP_CALL_MODAL_STATE,
    isVoipCallModalOpen,
  };
}
export function getRegisteredUserAction(value) {
  return {
    type: GET_REGISTERED_USER,
    searchValue: value,
  };
}

export function addMeetingAttendeeAction(email, meeting_id, user_id) {
  return {
    type: ADD_MEETING_ATTENDEE,
    email,
    meeting_id,
    user_id,
  };
}

export function subscribeParticipantsDataAction(meetingId) {
  return {
    type: SUBSCRIBE_PARTICIPANTS_DATA,
    meetingId,
  };
}

export function getVoipTokenAction() {
  return {
    type: GET_VOIP_TOKEN,
  };
}

export function storeVoipCallDetailsAction(call_external_id) {
  return {
    type: STORE_VOIP_CALL_DETAILS,
    call_external_id,
  };
}
export function setSecCamEnvCheckRequestTypeAction(requestType) {
  return {
    type: SEC_CAM_ENV_CHECK_REQUEST_TYPE,
    requestType,
  };
}

export function getSecCamEnvCheckRedirectionUrlAction({ requestType, env }) {
  return {
    type: FETCH_SEC_CAM_ENV_CHECK_REDIRECTION_URL,
    requestType,
    env,
  };
}
