/*
 * ConnectionIssue page Messages
 *
 * This contains all the text for the ConnectionIssue
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.IR.ConnectionIssue';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'We’ve encountered an issue while loading the application',
  },
  message: {
    id: `${scope}.message`,
    defaultMessage: 'Click refresh to try again.',
  },
  refresh: {
    id: `${scope}.refresh`,
    defaultMessage: 'Refresh',
  },
});
