import Video from 'twilio-video';

const TwilioService = () => {
  const connect = async (options) =>
    Video.connect(options.providerToken, {
      ...options.optionsRef.current,
      dominantSpeaker: true,
      audio: true,
      video: true,
      tracks: options.tracks,
      networkQuality: {
        local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
        remote: 2, // RemoteParticipants' Network Quality verbosity [0 - 3]
      },
    })
      .then((room) => {
        room.setMaxListeners(13);
        room?.localParticipant?.videoTracks.forEach((publication) => {
          // All video tracks are published with 'low' priority because the video track
          // that is displayed in the 'MainParticipant' component will have its priority
          // set to 'high' via track.setPriority()
          if (publication.setPriority) {
            publication.setPriority('low');
          }
        });
        options.onSuccess(room);

        room.on('disconnected', () => {
          options.onDisconnect({ tracks: options.tracks });
        });

        room.on('localTrackPublished', async () => {
          if (options.LocalTrackPublished) options.LocalTrackPublished();
        });
      })
      .catch((e) => {
        e.message = `Error connecting to twilio room: ${e?.message}`;
        options.onError(e);
      });
  return {
    connectProvider: connect,
  };
};

export default TwilioService;
