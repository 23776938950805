import { useState, useEffect, useRef } from 'react';
import { filter, includes } from 'lodash';
import { getDeviceInfo } from 'utils/media';
import { err } from 'utils/logger';
import { inputDevicesCustomIdList } from 'containers/InterviewRooms/utils';

// This returns the type of the value that is returned by a promise resolution

export default function useDevices(props) {
  const { permissionQuery, callback, localConnectedTracks } = props || {};
  const [loading, setLoading] = useState(false);

  const [deviceInfo, setDeviceInfo] = useState({
    audioInputDevices: [],
    videoInputDevices: [],
    audioOutputDevices: [],
    hasAudioInputDevices: false,
    hasVideoInputDevices: false,
  });

  const preDeviceInfoRef = useRef(deviceInfo);

  useEffect(() => {
    if (navigator?.permissions && permissionQuery) {
      try {
        navigator.permissions
          .query({ name: permissionQuery })
          .then((permissionStatus) => {
            // eslint-disable-next-line no-param-reassign
            permissionStatus.onchange = () => {
              if (permissionStatus?.state === 'granted') {
                getDeviceInfo().then((devices) => setDeviceInfo(devices));
              }
            };
          })
          .catch((e) => err('Error checking permission status change: ', e));
      } catch (e) {
        err('Error checking permission status: ', e);
      }
    }
  }, [permissionQuery]);

  const getDevices = async (invokeType) => {
    try {
      const devices = await getDeviceInfo();
      const checkAudioInputDeviceLength =
        devices?.audioInputDevices?.length > preDeviceInfoRef?.current?.audioInputDevices?.length;
      const checkCameraInputDeviceLength =
        devices?.videoInputDevices?.length > preDeviceInfoRef?.current?.videoInputDevices?.length;
      let isAudioDevicesAdded = [];
      if (preDeviceInfoRef?.current?.audioInputDevices?.length === devices?.audioInputDevices?.length) {
        isAudioDevicesAdded = filter(
          inputDevicesCustomIdList(preDeviceInfoRef?.current?.audioInputDevices),
          (item) => !includes(inputDevicesCustomIdList(devices?.audioInputDevices), item),
        );
      }
      preDeviceInfoRef.current = devices;

      setDeviceInfo(devices);

      if (
        ['init', 'change'].includes(invokeType) &&
        typeof callback === 'function' &&
        localConnectedTracks?.length > 0
      ) {
        callback(
          devices,
          checkAudioInputDeviceLength,
          isAudioDevicesAdded,
          checkCameraInputDeviceLength,
          localConnectedTracks,
        );
      }
    } catch (error) {
      err('Error fetching devices:', error);
    }
  };

  const deviceChangeListener = () => {
    getDevices('change');
  };

  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', deviceChangeListener);

    if (!loading) {
      getDevices('init');
      setLoading(true);
    }

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', deviceChangeListener);
    };
  }, [callback, localConnectedTracks]);

  return deviceInfo;
}
