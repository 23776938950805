import React, { useEffect, useState } from 'react';
import { Tabs, TabsHeader, Tab, TabsBody } from '@material-tailwind/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { find, some } from 'lodash';
import { getWindowObject, setWindowObject } from 'containers/InterviewRooms/utils';
import { classnamesConfigMap } from 'components/base/Tabs/configMap';

function BasicTabs({ tabs, type, currentTab }) {
  const verificationStatus = getWindowObject('verificationStatus');
  const updateTab = verificationStatus ? 1 : 0;
  const [activeTab, setActiveTab] = useState(currentTab || tabs[updateTab]?.value);
  useEffect(() => {
    if (verificationStatus) setWindowObject('verificationStatus', false);
  }, []);

  return (
    <Tabs value={activeTab} id="tabs" className="flex flex-col h-full rounded-lg">
      <TabsHeader
        className={classNames(
          'bg-transparent py-0 border border-solid border-red-500 flex',
          classnamesConfigMap[type || 'default']?.header,
        )}
        indicatorProps={{
          className: classNames(
            'bg-transparent shadow-none rounded-none',
            classnamesConfigMap[type || 'default']?.indicatorProps,
          ),
        }}
      >
        {tabs?.map(({ label, value, onClickFn, hasUnreadMessages }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => {
              if (onClickFn) onClickFn();
              setActiveTab(value);
            }}
            className={classNames(
              'w-fit relative',
              activeTab === value ? 'dark:text-white text-black' : 'dark:text-neutral-400 text-slate-400',
              classnamesConfigMap[type || 'default']?.tabs,
            )}
          >
            {hasUnreadMessages && activeTab !== value && (
              <div
                data-testid="latestMsgIndicator"
                className="absolute top-1.5 -right-0.5 w-2.5 h-2.5 rounded-xl bg-green-500"
              ></div>
            )}
            {label}
          </Tab>
        ))}
      </TabsHeader>
      {some(tabs, 'content') && (
        <TabsBody className="h-full text-slate-900 dark:text-slate-50">
          {find(tabs, (tab) => tab?.value === activeTab)?.content}
        </TabsBody>
      )}
    </Tabs>
  );
}

BasicTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      content: PropTypes.node,
    }),
  ).isRequired,
  type: PropTypes.string,
};

export default BasicTabs;
