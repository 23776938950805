import { GET_EVALUATION, EVALUATE_ACTION } from 'containers/Evaluation/queries';
import { find, get, map } from 'lodash';
import Auth from 'utils/auth';

export async function getEvaluationDetails({ id }) {
  try {
    const apolloClient = await Auth.recruitClient({ refresh: true });
    const response = await apolloClient.query({
      query: GET_EVALUATION,
      fetchPolicy: 'network-only',
      variables: { id },
    });

    // @todo Top evaluation changes pending
    const evaluated_by = Number(get(Auth, 'recruitStored.user.uid'));
    const evaluation = get(
      find(get(response, 'data.assessment_section_candidate_by_pk.panel_candidates', []), [
        'panel.user.id',
        evaluated_by,
      ]) || [],
      'evaluations.0',
      null,
    );
    const assessmentSectionCandidateId = get(response, 'data.assessment_section_candidate_by_pk.id', null);

    return {
      evaluation,
      step: get(response, 'data.assessment_section_candidate_by_pk', {}),
      assessmentSectionCandidateId,
    };
  } catch (err) {
    return err;
  }
}

export async function saveEvaluation({ rating, files, recommendation, id, evaluationComment, formData }) {
  try {
    const apolloClient = await Auth.recruitClient({ refresh: true });
    const response = await apolloClient.mutate({
      mutation: EVALUATE_ACTION,
      variables: {
        data: [
          {
            rating: map(rating, (r) => ({ parameter_id: r.parameter_id, rating: r.rating })),
            recommendation,
            assessmentSectionCandidateID: parseInt(id, 10),
            evaluationComment,
            formData,
            files,
          },
        ],
      },
    });
    return response;
  } catch (err) {
    return err;
  }
}
