import React from 'react';
import {
  concat,
  difference,
  filter,
  find,
  get,
  groupBy,
  identity,
  includes,
  map,
  maxBy,
  orderBy,
  round,
  uniqBy,
  some,
  trim,
  isObject,
  has,
  capitalize,
  isString,
} from 'lodash';
import { DateTime, Duration } from 'luxon';
import * as Sentry from '@sentry/react';
import { BackgroundBlur, VirtualBackground } from '@livekit/track-processors';
import { getItem } from 'utils/store';
import { log } from 'utils/logger';
import { isMobile, isIOS, getOrientation } from 'utils/media';
import { ROLE_MAP, DEFAULT_NAME, CONNECTED_TYPE, ACCEPTED_TYPE, REQUEST_TYPE } from 'utils/constants';
import {
  IR_MESSAGE_TYPES,
  IR_SIGNAL_ICONS,
  IR_SIGNAL_SOURCE,
  IR_SIGNAL_TYPES,
  NOTIFICATION_TYPE,
} from 'utils/signalConstants';
import { providerOptions, providersPriority } from 'contexts/VideoProvider/constants';
import { mixpanelEvents } from 'utils/mixpanelEvents';
import { DEFAULT_VIDEO_CONSTRAINTS, DEFAULT_MOBILE_VIDEO_CONSTRAINTS } from 'containers/App/constants';
import { effectsMap, interviewRoomsTabs } from 'containers/InterviewRooms/constants';

export const supportedFiles = ['.xls', '.xlsx', '.pdf', '.doc', '.docx', '.ppt', '.pptx', '.jpg', '.jpeg', '.png'];

export const directionMap = (direction) => {
  switch (direction) {
    case 'left':
    case 'right':
      return {
        enterFrom: 'translate-x-full',
        enterTo: 'translate-x-0',
        leaveFrom: 'translate-x-0',
        leaveTo: 'translate-x-full',
      };
    case 'top':
    case 'bottom':
      return {
        enterFrom: 'translate-y-full',
        enterTo: 'translate-y-0',
        leaveFrom: 'translate-y-0',
        leaveTo: 'translate-y-full',
      };
    default:
      return null;
  }
};

export const getParticipantDetails = (currentParticipant, authUserData) => {
  const participant_email =
    get(currentParticipant, '0.email') ||
    get(currentParticipant, '0.username') ||
    get(currentParticipant, '0.user_details.email') ||
    get(currentParticipant, '0.guest.email') ||
    get(authUserData, 'username') ||
    null;
  const participant_name =
    get(currentParticipant, '0.name') ||
    get(currentParticipant, '0.user.profilesByUserId.0.name') ||
    get(currentParticipant, '0.user_details.name') ||
    get(currentParticipant, '0.guest.name') ||
    (get(authUserData, 'name') === 'ca_temp' ? 'Candidate' : get(authUserData, 'name')) ||
    null;
  const participant_role = get(currentParticipant, '0.role') || get(currentParticipant, '0.participant_role') || null;
  return { participant_email, participant_name, participant_role };
};

export const getLiveAttendees = (meetingDetails) =>
  uniqBy(
    map(get(meetingDetails, '0.live_attendees') || get(meetingDetails, '0.meeting_participants'), (i) => {
      const i_obj = { ...i };
      i_obj.name = i?.name || i?.user?.profilesByUserId?.[0]?.name || i?.user_details?.name || i?.guest?.name;
      i_obj.email = i?.email || i?.username || i?.user_details?.email || i?.guest?.email;
      return i_obj;
    }),
    'email',
  );

export const getGroupedParticipants = (participants) =>
  groupBy(
    participants,
    (participant) => ROLE_MAP[participant?.role] || ROLE_MAP[participant?.participant_role] || 'Participants',
  );

export function getCurrentParticipant(isCandidate, meetingDetails, participantData, participant) {
  return filter(
    get(meetingDetails, '0.live_attendees') || get(meetingDetails, '0.meeting_participants') || participantData || [],
    (i) => Number(i?.meeting_participant_id || i?.id) === Number(participant?.identity),
  );
}

export const candidateRoleCheck = (role) => ['GUEST', 1, 'Candidate', 'CANDIDATE'].includes(role);

export const handleCopyTextClick = (copyTextRef) => {
  if (copyTextRef?.current) {
    navigator?.clipboard?.writeText(copyTextRef?.current?.innerText);
  }
};

export function isJson(item) {
  let value = typeof item !== 'string' ? JSON.stringify(item) : item;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }
  return typeof value === 'object' && value !== null;
}

export const generateFaceAuthDescription = (payload, type) => {
  const description = isJson(payload) ? JSON.parse(payload) : payload;
  if (isJson(description)) {
    if (type) return get(description, `${type}`);
    return (
      <>
        <p>{description?.section}</p>
        <p>{description?.assessment}</p>
      </>
    );
  }
  try {
    const [session_name, section_title, assessment_external_id] = description.split(/[(,)]/).map((str) => str.trim());
    let updated_description = session_name;
    if (section_title && assessment_external_id) {
      updated_description += `(${section_title}, ${assessment_external_id})`;
    } else if (section_title || assessment_external_id) {
      updated_description += `(${section_title || assessment_external_id})`;
    }
    return updated_description;
  } catch (e) {
    return description;
  }
};

export const formatTimeDifference = (date1, date2) => {
  try {
    const durationInMinutes = date1.diff(date2, 'minutes').toObject().minutes;
    return round(Number(durationInMinutes), 2);
  } catch {
    return null;
  }
};

export const formatSeconds = (seconds) => {
  try {
    if (seconds < 0) return '00:00';
    const duration = Duration.fromObject({ seconds });
    const hours = Math.floor(duration.as('hours'));
    return hours > 0 ? duration.toFormat('hh:mm:ss') : duration.toFormat('mm:ss');
  } catch {
    return '00:00';
  }
};

export const getMeetingDurations = ({ roomCreated, meetingDetails }) => {
  try {
    const currentTime = DateTime.now();
    const { starts_at, ends_at } = get(meetingDetails, '0', {});

    const scheduledDuration =
      ends_at && starts_at && formatTimeDifference(DateTime.fromISO(ends_at), DateTime.fromISO(starts_at));
    const actualDuration = roomCreated && formatTimeDifference(currentTime, roomCreated);
    const timeDiff = scheduledDuration && actualDuration && scheduledDuration - actualDuration;

    return { scheduledDuration, actualDuration, timeDiff };
  } catch {
    return { scheduledDuration: null, actualDuration: null, timeDiff: null };
  }
};

export const notifyMeetingEndSoon = ({ setSelfNotificationData, meetingEndTime }) => {
  const endTime = DateTime.fromISO(meetingEndTime);

  const notificationTime = endTime?.minus({ minutes: 5 });

  // Function to check if it's time to notify
  function checkNotification() {
    try {
      const now = DateTime.now();
      const timeLeft = round(formatTimeDifference(endTime, now), 0);
      if (!timeLeft || timeLeft < 0) clearInterval(intervalId);
      if (now >= notificationTime && now < endTime) {
        setSelfNotificationData({
          signal_type: IR_SIGNAL_TYPES.NOTIFICATION,
          signal_message: `Session ends in ${timeLeft} minutes`,
          message_type: IR_MESSAGE_TYPES.SESSION_ENDS_SOON,
          notification_type: NOTIFICATION_TYPE.INFO,
          source: IR_SIGNAL_SOURCE.SELF,
          id: Math.random(),
        });
        clearInterval(intervalId); // Clear the interval after notifying
      }
    } catch {
      clearInterval(intervalId);
    }
  }

  const intervalId = setInterval(checkNotification, 60 * 1000);
};

export const getDeviceLabel = (devicesList, id) =>
  get(
    find(devicesList, (e) => e?.deviceId === id || e?.groupId === id),
    'label',
    '',
  );

export function updateMediaConstraints(videoInputDevices, selectedVideoDeviceId, orientation, isTwilioProvider) {
  try {
    const filteredVideoDevice = filter(
      videoInputDevices,
      (device) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId,
    );
    const device = filteredVideoDevice?.length ? get(filteredVideoDevice, '0') : get(videoInputDevices, '0');
    const supportedCapabilities = device?.getCapabilities?.();
    if (isMobile && isIOS) {
      const orientationValue = orientation || getOrientation();
      const mobileConstraints = DEFAULT_MOBILE_VIDEO_CONSTRAINTS.h360[orientationValue];
      if (mobileConstraints) {
        const constraintsObject = {
          resolution: {
            height: { ideal: mobileConstraints.height.ideal },
            width: { ideal: mobileConstraints.width.ideal },
          },
          frameRate: 24,
        };
        if (!isTwilioProvider) constraintsObject.aspectRatio = 3 / 4;
        return constraintsObject;
      }
    } else if (get(supportedCapabilities, 'height.max')) {
      return {
        ...DEFAULT_VIDEO_CONSTRAINTS,
        resolution: {
          height: { ideal: get(supportedCapabilities, 'height.max', 3456) },
          width: { ideal: get(supportedCapabilities, 'width.max', 4608) },
        },
      };
    }
    return DEFAULT_VIDEO_CONSTRAINTS;
  } catch (e) {
    console.log('Error', e);
    return DEFAULT_VIDEO_CONSTRAINTS;
  }
}

export const inputDevicesCustomIdList = (inputDevices) =>
  inputDevices.map((device) => {
    if (device?.groupId && device?.label) {
      const label = device?.label.includes('Default - ') ? device?.label.replace('Default - ', '') : device?.label;
      return `${device?.groupId} ${label}`;
    }
    return null;
  });

export const inputDevicesLabelList = (inputDevices) => filter(map(inputDevices, 'label', []), identity);

export const removeDefaultFromLabel = (label) =>
  label?.includes('Default - ') ? label.replace('Default - ', '').trim() : label;

export const getConnectedDeviceLabel = (inputDevices, track) => {
  const filteredDevices = inputDevices.filter(
    (device) => device.groupId === track?.mediaStreamTrack?.getSettings()?.groupId,
  );
  return filteredDevices?.[0]?.label || null;
};

export const getInMeetingStatus = (participantData, isCandidate) => {
  const panelInMeetingStatus = participantData?.find(
    (participant) => participant?.role === 'Evaluator' || participant?.role === 'Organizer',
  );
  const candidateInMeetingStatus = participantData?.find((participant) => participant?.role === 'Candidate');

  return isCandidate ? panelInMeetingStatus || null : candidateInMeetingStatus || null;
};

export const meetingLoadingTimeTaken = (time) => {
  try {
    const currentTime = JSON.parse(JSON.stringify(DateTime.now()));
    const savedLoadingStartTime = getItem('ir_loading_started_at');
    const parsedLoadingStartTime = savedLoadingStartTime && JSON.parse(savedLoadingStartTime);
    return formatTimeDifference(DateTime.fromISO(currentTime), DateTime.fromISO(time || parsedLoadingStartTime));
  } catch {
    return null;
  }
};

export const formatDuration = (timeString) => {
  const durationHours = DateTime.fromISO(timeString).toFormat('h');
  const durationMinutes = DateTime.fromISO(timeString).toFormat('m');

  const hoursText = durationHours > 1 ? 'Hours' : 'Hour';
  const minutesText = durationMinutes > 1 ? 'Minutes' : 'Minute';

  return `${durationHours} ${hoursText} ${durationMinutes} ${minutesText}`;
};

export const getParticipantsJoinedList = (props) => {
  const { meetingDetails, participantsJoinedList } = props || {};

  const { id: candidateId } = find(get(meetingDetails, '0.live_attendees'), { role: 1 }) || {};
  const { id: organizerId } = find(get(meetingDetails, '0.live_attendees'), { role: 3 }) || {};
  const candidateJoined = includes(participantsJoinedList, candidateId) ? 1 : 0;
  const organizerJoined = includes(participantsJoinedList, organizerId) ? 1 : 0;

  const interviewersList = map(filter(get(meetingDetails, '0.live_attendees'), { role: 2 }), (i) => i?.id);
  const interviewersJoined = get(
    filter(participantsJoinedList, (i) => includes(interviewersList, i)),
    'length',
    0,
  );

  return {
    candidateParticipantStatus: candidateJoined,
    organizerParticipantStatus: organizerJoined,
    numberOfInterviewersJoined: interviewersJoined,
    totalParticipantsJoined: candidateJoined + organizerJoined + interviewersJoined,
  };
};

export const getSessionUsers = (participantData) => {
  try {
    const grouped = groupBy(participantData, 'email');
    const activeParticipants = map(grouped, (group) => maxBy(group, 'created_at'));
    const whiteboardUsers = activeParticipants?.filter((user) => user?.current_tab === interviewRoomsTabs.BOARD) || [];
    const codeJamUsers = activeParticipants?.filter((user) => user?.current_tab === interviewRoomsTabs.CODE_JAM) || [];
    return { whiteboardUsers, codeJamUsers };
  } catch {
    return { whiteboardUsers: [], codeJamUsers: [] };
  }
};

export const getRecordingValue = (hash) => {
  try {
    const regex = /startRecording=([^&]*)/;
    const match = hash.match(regex);
    if (match) {
      return match[1] === 'false' ? 'false' : 'true';
    }
    return 'true';
  } catch (e) {
    return 'false';
  }
};

export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const isDefinedAndNotNull = (value) => value !== undefined && value !== null;

export const findTrackByKind = (tracks, kind) => find(tracks, (track) => track?.kind === kind) || null;

export const fetchFallbackProviders = (participantData) =>
  find(orderBy(participantData, 'created_at', 'desc'), (i) => i?.fallback_video_service_providers) || {
    fallback_video_service_providers: [],
  };

export const getValidProvider = (
  fallbackProviderContext,
  fallback_video_service_providers,
  initialVideoProvider,
  videoServiceProvidersPriority,
) => {
  try {
    const providerPriorityList = videoServiceProvidersPriority?.video_providers || providersPriority;
    // Combine fallbackContext and fallback_service_provider
    const exclusions = concat(fallbackProviderContext, fallback_video_service_providers, initialVideoProvider);

    // Get the first valid provider not in the exclusions list
    const validProviders = difference(providerPriorityList, exclusions);

    return validProviders[0] || null; // Return the first valid provider or null if none found
  } catch {
    return null;
  }
};

export const setWindowObject = (key, value) => {
  try {
    const state = (window && window['ir.state'] && JSON.parse(window['ir.state'])) || {};
    if (state) {
      state[key] = value;
      window['ir.state'] = JSON.stringify(state);
    }
  } catch (e) {
    const error = new Error(`Error setting window state, error: ${e}`);
    console.error(error);
    Sentry.captureException(error);
  }
};

export const getWindowObject = (key) => {
  try {
    if (key) {
      const state = (window && window['ir.state'] && JSON.parse(window['ir.state'])) || {};
      return get(state, `${key}`);
    }
    return null;
  } catch (e) {
    Sentry.captureException(new Error(`Error loading window state, error: ${e}`));
    return null;
  }
};

export const containsTags = (inputString, tags) => {
  try {
    let modifiedString = inputString;
    tags.forEach((tag) => {
      const openingTagRegex = new RegExp(`<${tag}\\b[^>]*>`, 'gi');
      const closingTagRegex = new RegExp(`</${tag}>`, 'gi');
      if (includes(modifiedString, '&nbsp;')) {
        modifiedString = modifiedString?.replace(/&nbsp;/g, '');
      }
      modifiedString = modifiedString?.replace(openingTagRegex, '');
      modifiedString = modifiedString?.replace(closingTagRegex, '');
    });
    return modifiedString?.trim();
  } catch (e) {
    Sentry.captureException(new Error(`Error in containsTags function: ${e}`));
    return null;
  }
};
export function setCanvasDimensions(track) {
  const { height, width } = get(track, 'dimensions') || {};
  if (height && width)
    try {
      // eslint-disable-next-line no-param-reassign
      track.processor.canvas.height = height;
      // eslint-disable-next-line no-param-reassign
      track.processor.canvas.width = width;
    } catch (e) {
      Sentry.captureException(new Error(`Error setting canvas dimension only blur apply: ${e}`));
    }
}

export function overallVerificationStatus({ authVerificationDetails }) {
  if (!authVerificationDetails || authVerificationDetails.length === 0) return null;
  return some(authVerificationDetails, (detail) => detail?.is_identical === false) ? 'failed' : 'passed';
}

export function isCandidateJoined({ remoteParticipants, participantData }) {
  const candidateId = get(
    find(participantData, (item) => item?.role === 'Candidate'),
    'meeting_participant_id',
    null,
  );
  return find(
    remoteParticipants,
    (item) => item?.metadata === 'CANDIDATE' || Number(item?.identity) === Number(candidateId),
  );
}

export const applyBackgroundEffect = async (videoTrack, effect) => {
  try {
    if (effect === effectsMap.BLUR) await videoTrack?.setProcessor(BackgroundBlur(25));
    else if (!includes([effectsMap.BLUR, effectsMap.NONE], effect))
      await videoTrack?.setProcessor(VirtualBackground(effect));
    if (effect !== effectsMap.NONE) await setCanvasDimensions(videoTrack);
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const applyEffectOnInitialization = async (videoTrack, serviceProvider, background_images) => {
  const videoEffect = getItem('irVideoEffect');
  if (
    serviceProvider !== providerOptions.TWILIO &&
    (background_images?.includes(videoEffect) || videoEffect === effectsMap.BLUR)
  ) {
    await applyBackgroundEffect(videoTrack, videoEffect);
  }
};

export const convertCanvasToBlob = (canvas) =>
  new Promise((resolve, reject) => {
    try {
      canvas.toBlob((blob) => {
        if (!blob) {
          log('Failed to convert canvas to blob.');
          reject(new Error('Failed to convert canvas to blob.'));
          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve({ dataURL: reader.result });
        reader.onerror = () => reject(new Error('Failed to read blob as data URL.'));
      }, 'image/jpeg');
    } catch (error) {
      log('An error occurred during canvas to blob conversion:', error);
      reject(new Error('An error occurred during canvas to blob conversion'));
    }
  });

export const drawImageOnCanvas = (videoElement, canvas, context) => {
  const localCanvas = canvas;
  localCanvas.width = videoElement.videoWidth;
  localCanvas.height = videoElement.videoHeight;

  try {
    context.drawImage(videoElement, 0, 0, localCanvas.width, localCanvas.height);
  } catch (error) {
    log('Failed to draw image on canvas:', error);
    return false;
  }
  return true;
};

export const getVideoElement = () => {
  const videoElement = document.getElementById('local-video');
  if (!videoElement || videoElement.videoWidth === 0 || videoElement.videoHeight === 0) {
    log('Video element is not valid or has no dimensions.');
    return null;
  }
  return videoElement;
};

export const captureVideoFrame = async ({ uploadImage }) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const videoElement = getVideoElement();
  if (!videoElement) return;

  const isDrawImageOnCanvasSuccess = drawImageOnCanvas(videoElement, canvas, context);
  if (!isDrawImageOnCanvasSuccess) return;

  await convertCanvasToBlob(canvas).then((result) => {
    if (result?.dataURL) uploadImage({ base64data: result?.dataURL });
  });
};

export const inlineAndBlockTextTags = ['nbsp', 'br', 'p', 'pre', 'code'];

export const getMissingUserData = (newMeetingDetails, oldMeetingDetails) => {
  const oldUserIds = map(oldMeetingDetails, (user) => user?.user_id);
  return filter(newMeetingDetails, (user) => !includes(oldUserIds, user?.user_id));
};

export const createUserDetailsMap = (userDetailsResponse) =>
  userDetailsResponse?.reduce((acc, item) => {
    const user = get(item, 'auth_user_by_pk');
    if (user && user?.id !== null) {
      return { ...acc, [user?.id]: user };
    }
    return acc;
  }, {});

export const getUpdatedDetails = (missingUserData, userDetailsMap) =>
  map(missingUserData, (user) => ({
    ...user,
    user_details: userDetailsMap[user?.user_id],
  }));

export const isEmail = (value) => isString(value) && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

export const getCandidateName = (candidateDetails) => {
  const name = trim(get(candidateDetails, 'firstName') || '');
  if (isEmail(name)) return 'Candidate';
  const firstName = capitalize(name);
  const lastName = capitalize(trim(get(candidateDetails, 'lastName') || ''));
  const fullName = [firstName, lastName].filter(Boolean).join(' ');

  return trim(fullName) || 'Candidate';
};

export const userAgent = navigator.userAgent || navigator.vendor || window.opera;

export const exitParentFrame = () => {
  const iosDevice = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  const androidDevice = /android/i.test(userAgent);
  const payload = { IRleaveBtnClick: true };
  const mpayload = { type: 'IRleaveBtnClick', leaveMeeting: true };

  if (iosDevice) window?.webkit?.messageHandlers?.iosClient?.postMessage(JSON.stringify(mpayload));
  // eslint-disable-next-line no-undef
  else if (androidDevice && typeof AndroidForms !== 'undefined') AndroidForms?.formData(JSON.stringify(mpayload));
  else window?.parent?.postMessage(payload, '*');
};

export function getSignalIconSrc(key, isDarkMode) {
  const icon = IR_SIGNAL_ICONS[key];
  if (icon) {
    if (isObject(icon) && (has(icon, 'dark') || has(icon, 'light'))) {
      // Return the corresponding mode value if it's an object and has dark or light key's
      return isDarkMode ? icon.dark : icon.light;
    }
    return icon;
  }
  return null;
}

export const getDefaultName = (participant, participant_role) => {
  if (participant?.identity?.startsWith('sc-')) {
    return DEFAULT_NAME.SECONDARY_CAMERA;
  }
  return candidateRoleCheck(participant_role) ? DEFAULT_NAME.CANDIDATE : DEFAULT_NAME.INTERVIEWER;
};

export const checkSecCamEnvCheckStatus = (messageType, secondaryCameraParticipant) => {
  const disconnected = includes(CONNECTED_TYPE, messageType) && !secondaryCameraParticipant;
  const acceptedOrDisconnected = includes(ACCEPTED_TYPE, messageType) || disconnected;
  const connectedOrAccepted = includes([...CONNECTED_TYPE, ...ACCEPTED_TYPE], messageType);
  const secCamActive = messageType === REQUEST_TYPE.SECONDARY_CAMERA_CONNECTED && secondaryCameraParticipant;
  const envCheckActive = messageType === REQUEST_TYPE.ENV_CHECK_CONNECTED;

  return {
    isSecCamDisconnected: disconnected,
    SecCamAcceptedOrDisconnectedState: acceptedOrDisconnected,
    ConnectedOrAcceptedState: connectedOrAccepted,
    isSecCamActive: secCamActive,
    isEnvCheckActive: envCheckActive,
  };
};

export const logEffectsAppliedMixpanelEvent = ({ selectedEffect, logEvent }) => {
  let event = '';
  if (selectedEffect === effectsMap.NONE) event = 'EFFECTS_APPLIED_NONE';
  else if (selectedEffect === effectsMap.BLUR) event = 'EFFECTS_APPLIED_BLUR';
  else event = 'EFFECTS_APPLIED_CUSTOM';
  logEvent(get(mixpanelEvents, event));
};

export const isSCParticipant = (participant) => participant?.identity?.startsWith('sc-');

export const getSecondaryCameraParticipant = (participants = []) =>
  find(participants, (participant) => isSCParticipant(participant));
