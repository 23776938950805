import { remove, map, filter, get } from 'lodash';

export const processEvent = (cb, event, ref, Auth) => {
  if (event.data.type === 'FormInitialized') {
    if (ref.current) {
      ref.current.contentWindow.postMessage(
        {
          type: 'gotAccessToken',
          accessToken: `Bearer ${Auth.restToken}`,
        },
        '*',
      );
    }
  }
  if (event.data.type === 'formData') {
    const formData = get(event, 'data.formData', []);
    const files = map(filter(formData, (d) => d.isFile) || [], (data) => ({
      id: data.value.id,
      key: data.label,
    }));
    const filteredFormData = files.length ? remove(formData, (d) => (d.isFile ? false : d)) : formData;
    if (filteredFormData.length) cb(filteredFormData, files);
  }
};

export default processEvent;
