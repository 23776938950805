import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';
import { faMicrophone, faMicrophoneSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar } from 'components/base';
import Status from 'components/Status';
import { ROLE_MAP } from 'utils/constants';

// export const getRole = role => role.charAt(0).toUpperCase() + role.slice(1).toLowerCase();

export const fetchStatus = (status) => {
  switch (status) {
    case 'INVITED':
      return (
        <div className="flex items-center">
          <span className="h-1 w-1 inline rounded-full dark:bg-neutral-400 bg-slate-400  mr-1"></span>
          <span className="text-xs dark:text-neutral-400 text-slate-400  text-center font-normal">No response</span>
        </div>
      );
    case 'ACCEPTED':
      return (
        <div className="flex items-center">
          <span className="h-1 w-1 inline rounded-full bg-green-500 mr-1"></span>
          <span className="text-xs text-center dark:text-green-500 text-green-400 font-normal">Accepted</span>
        </div>
      );
    case 'DECLINED':
      return (
        <div className="flex items-center">
          <span className="h-1 w-1 inline rounded-full dark:bg-red-500 bg-red-400 mr-1"></span>
          <span className="text-xs text-center dark:text-red-500 text-red-400 font-normal">Declined</span>
        </div>
      );
    default:
      return null;
  }
};

export const getStatus = (data, localParticipant) =>
  Number(localParticipant?.identity) !== data?.meeting_participant_id ? fetchStatus(data?.rsvp_status) : <p>(You)</p>;

function ParticipantItem(props) {
  const { size = 'sm', data = {}, micStatus, localParticipant, avatarSize, description, evaluationStatus } = props;
  const participantName = data?.name || data?.first_name || data?.email;

  const sizes = useMemo(
    () => ({
      xs: {
        wrapper: 'space-x-2',
        title: 'text-xs',
        description: 'text-xs',
      },
      sm: {
        wrapper: 'space-x-3',
        title: 'text-sm',
        description: 'text-xs',
      },
      md: {
        wrapper: 'space-x-4',
        title: 'text-base',
        description: 'text-xs',
      },
      lg: {
        wrapper: 'space-x-5',
        title: 'text-lg',
        description: 'text-base',
      },
    }),
    [],
  );

  return (
    <div
      className={classNames(
        'flex flex-1 items-center sm:w-96 py-2 w-fit',
        !evaluationStatus &&
          !avatarSize &&
          'border dark:border-gray-900 border-slate-200 border-t-0 border-l-0 border-r-0',
        sizes[size]?.wrapper || sizes.sm.wrapper,
      )}
    >
      <Avatar data={data} size={avatarSize || 'sm'} />
      <div className="w-[18.438rem] flex flex-1 flex-col space-y-1 overflow-hidden  ">
        <div
          className={classNames(
            get(sizes[size], 'title') || get(sizes, 'sm.title'),
            'font-semibold dark:text-neutral-50 text-slate-900 flex space-x-2',
          )}
        >
          <span className="max-w-[13.125rem] truncate">{participantName}</span>
          {!evaluationStatus && getStatus(data, localParticipant)}
        </div>
        {evaluationStatus && description && (
          <p className="dark:text-neutral-400 text-slate-400 text-xs font-medium mb-2 truncate">{description}</p>
        )}
        {data?.participantRole && (
          <div
            className={classNames(
              sizes[size]?.description || sizes.sm.description,
              'dark:text-neutral-200 text-slate-600 ',
            )}
          >
            {ROLE_MAP[data?.participantRole] || data?.participantRole}
          </div>
        )}
        {evaluationStatus && <Status status={evaluationStatus} />}
      </div>
      {!evaluationStatus && !avatarSize && (
        <div className="justify-center items-center h-6 w-6 hidden">
          <FontAwesomeIcon icon={micStatus ? faMicrophone : faMicrophoneSlash} className="text-white" size="lg" />
        </div>
      )}
    </div>
  );
}

ParticipantItem.propTypes = {
  data: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    profile_pic: PropTypes.string,
    phone: PropTypes.number,
  }),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
};

export default ParticipantItem;
