export const growthBookFeatureFlags = {
  MIX_PANEL_ENABLED: 'mixpanel_enabled',
  INTERVIEW_ROOMS_CANDIDATE_INMEETING_FACE_AUTH: 'ir_inmeeting_authentication',
  INTERVIEW_ROOMS_CODE_JAM_ENABLED: 'ir_code_jam',
  INTERVIEW_ROOMS_DOWNLOAD_RESUME: 'ir_resume_download',
  INTERVIEW_ROOMS_INTERVIEW_BUILDER: 'ir_interview_builder',
  INTERVIEW_ROOMS_FEEDBACK_ENABLED: 'ir_feedback',
  INTERVIEW_ROOMS_WHITEBOARD: 'ir_whiteboard',
  INTERVIEW_ROOM_THEME_SWITCHER: 'ir_theme_switcher',
  INTERVIEW_ROOMS_ENABLE_EVALUATION: 'ir_evaluation',
  INTERVIEW_ROOMS_VIDEO_BACKGROUND_EFFECT: 'ir_video_background_image_url',
  INTERVIEW_ROOMS_MIN_DURATION: 'ir_interview_min_duration_notify_time',
  INTERVIEW_ROOMS_CHAT_LINK: 'ir_chat_link',
  INTERVIEW_ROOMS_CHAT_CODE_BLOCK: 'ir_chat_code_block',
  INTERVIEW_ROOMS_CANDIDATE_FACE_DETECT_NOTIFICATION: 'ir_inmeeting_auth_face_detection',
  NOICE_CANCELLATION: 'ir_noise_cancellation',
  INTERVIEW_ROOMS_DETECT_WINDOW_FOCUS_OUT: 'ir_window_focus_out_detect',
  IR_VIDEO_VP9: 'ir_video_vp9',
  IR_VIDEO_CAPTURE_DEFAULT_RESOLUTION: 'ir_video_capture_default_resolution',
  INTERVIEW_ROOMS_PARTICIPANT_INVITE: 'ir_participant_invite',
  IR_PARTICIPANT_JOINING_LATE_NOTIFY: 'ir_participant_joining_late_notify',
  IR_CODE_JAM_ADD_QUESTIONS: 'ir_code_jam_add_questions',
  IR_GUIDE_EDITOR_COLLABORATION: 'ir_guide_editor_collaboration',
  IR_VOIP_CALL: 'ir_voip_call',
  IR_VIDEO_SERVICE_PROVIDER_PRIORITY: 'ir_video_service_provider_priority',
  IR_SEC_CAM_REQUEST: 'ir_secondary_camera_request_enable',
  IR_360_ENV_CHECK_REQUEST: 'ir_360_env_check_request_enable',
  IR_PARTICIPANT_EGRESS_MP4_ENABLED: 'ir_livekit_participant_egress_mp4_enabled',
  IR_SEC_CAM_OBJECT_DETECTION_ENABLED: 'ir_sec_cam_object_detection_enabled',
  MEETING_ID_CONVERSION_ENABLED: 'enabled_ir_extra_query_params',
  IR_INTERVIEW_BUILDER_INITIAL_CONTENT: 'ir_interview_builder_initial_content',
  IR_SPLASH_SCREEN_ENABLED: 'ir_splash_screen_enabled',
  IR_INSIGHTS_DETAILED_VIEW_ENABLED: 'ir_insights_detailed_view_enabled',
  IR_INTERVIEW_BUILDER_HELP_ENABLED: 'ir_interview_builder_help_enabled',
  IR_TROUBLESHOOTING_ENABLED: 'ir_troubleshooting_enabled',
};
