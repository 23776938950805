import React, { useEffect, useState } from 'react';
import { get, includes } from 'lodash';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import TechnicalError from 'images/TechnicalError.svg';
import { getItem, setItem } from 'utils/store';
import { getWindowObject } from 'containers/InterviewRooms/utils';
import ConnectionIssue from 'components/IR/ConnectionIssue';

function TechnicalErrorPage(props) {
  const { error } = props;
  const meeting_details = getWindowObject('meeting_details') || {};
  const meetingId = get(meeting_details, 'meeting_id');
  const retryCount = getItem(`ir_retry_${meetingId}`) || 0;
  const [countDown, setCountDown] = useState(5 * (retryCount + 1));
  const isFirebaseError = includes(error?.message, 'FirebaseError');

  useEffect(() => {
    const timer = setInterval(() => {
      setCountDown((prevCounter) => {
        if (prevCounter === 0) {
          setItem(`ir_retry_${meetingId}`, retryCount + 1);
          window.location.reload();
          clearInterval(timer);
          return prevCounter;
        }
        return prevCounter - 1;
      });
    }, 1000);
    if (retryCount >= 4) clearTimeout(timer);
    return () => clearTimeout(timer);
  }, []);

  if (isFirebaseError)
    return (
      <ConnectionIssue
        showRefreshCountdown={retryCount < 4}
        countDown={countDown}
        onRefresh={() => window.location.reload()}
      />
    );

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center bg-white">
      <div className={`${error?.message ? 'flex-row sm:mx-36' : 'flex-col'} flex`}>
        <div className={`${error?.message ? ' sm:w-3/4 w-full text-left' : 'w-full text-center'} sm:p-8 p-2`}>
          <div className="dark:text-blue-600 text-blue-500 font-bold text-3xl mb-3">Something went wrong</div>
          {error?.message && (
            <div className="bg-gray-50 p-8 relative">
              <p>{error.message}</p>
              {error?.traceId && <p className="py-4">Trace Id: {error.traceId}</p>}
              <DocumentDuplicateIcon
                onClick={() => navigator?.clipboard?.writeText(`Trace Id: ${error?.traceId || error.message}`)}
                className="w-5 h-5 text-black stroke-2 absolute top-1 right-1 cursor-pointer"
              />
            </div>
          )}
        </div>
        <div className="p-8 sm:block hidden">
          <img src={TechnicalError} alt="Technical Error" className="my-4" />
        </div>
      </div>
      {retryCount < 4 && (
        <div className="py-4 text-base font-normal dark:text-neutral-500 text-slate-800  text-center">
          Refreshing in {countDown} secs
        </div>
      )}
      <div className="text-lg font-normal dark:text-neutral-500 text-slate-800 p-2">
        If the problem persists, please contact our{' '}
        <a
          href="https://talview.freshdesk.com/support/tickets/new"
          target="_blank"
          className="dark:text-blue-600 text-blue-500 font-medium"
        >
          support
        </a>{' '}
        team for assistance
      </div>
    </div>
  );
}

export default TechnicalErrorPage;
