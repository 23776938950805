import { useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, includes } from 'lodash';
import { TrackEvent } from 'livekit-client';
import useSignallingHook from 'hooks/useSignalling';
import { mixpanelEvents } from 'utils/mixpanelEvents';
import {
  selectFullViewTabsOpenState,
  selectIsCandidate,
  selectSideTabsOpenState,
} from 'containers/InterviewRooms/selector';
import { updateFullViewTabsOpenAction, updateSideTabsOpenAction } from 'containers/InterviewRooms/actions';
import VideoServiceInterface from 'containers/IRRedirect/videoServiceInterface';
import { IR_MESSAGE_TYPES, IR_SIGNAL_TYPES, SIGNAL_MESSAGE } from 'utils/signalConstants';
import { ROOM_EVENT_NAMES } from 'containers/InterviewRooms/constants';
import { ERROR_MESSAGE, ERROR_NAME, providerOptions } from 'contexts/VideoProvider/constants';

export const handleScreenSharingError = (error, onError) => {
  if (error) {
    if (error?.name !== ERROR_NAME.ABORT_ERROR && error?.name !== ERROR_NAME.NOT_ALLOWED_ERROR) {
      onError(error);
    }
    const errorMessage = error?.message;
    const isPermissionError =
      includes(errorMessage, ERROR_MESSAGE.LIVE_KIT_ERROR_MESSAGE) ||
      includes(errorMessage, ERROR_MESSAGE.TWILIO_ERROR_MESSAGE);
    if (isPermissionError) {
      onError({ signalMessage: SIGNAL_MESSAGE.SCREEN_SHARE_FAILED });
    }
  }
};

export default function useScreenShareToggle({ room, onError = () => {}, onSuccess = () => {} }) {
  const { irVideoServiceProvider } = VideoServiceInterface();

  const [sendSignalling] = useSignallingHook();
  const dispatch = useDispatch();
  const isCandidate = useSelector(selectIsCandidate);
  const [isSharing, setIsSharing] = useState(false);
  const stopScreenShareRef = useRef();
  const { isChatTabOpen, isGuideTabOpen } = useSelector(selectSideTabsOpenState) || {};
  const { isEvaluationTabOpen } = useSelector(selectFullViewTabsOpenState) || {};

  const shareScreen = useCallback(
    ({ participantDetails, logEvent, addIrEventLog }) => {
      const onScreenShareStart = () => {
        setIsSharing(true);
        if (!isCandidate) {
          if (isEvaluationTabOpen) dispatch(updateFullViewTabsOpenAction({}));
          if (isChatTabOpen || isGuideTabOpen) dispatch(updateSideTabsOpenAction({}));
        }
        logEvent(get(mixpanelEvents, 'MEETING_PARTICIPANT_STARTED_SCREEN_SHARE'));
        addIrEventLog(ROOM_EVENT_NAMES?.SCREENSHARE_START);
        sendSignalling(
          IR_SIGNAL_TYPES.NOTIFICATION,
          `${participantDetails?.name} is sharing the screen`,
          'Candidate',
          participantDetails?.email,
          IR_MESSAGE_TYPES.SCREEN_SHARE,
        );
        sendSignalling(
          IR_SIGNAL_TYPES.NOTIFICATION,
          `${participantDetails?.name} is sharing the screen`,
          'Panel',
          participantDetails?.email,
          IR_MESSAGE_TYPES.SCREEN_SHARE,
        );
        onSuccess({ signalMessage: SIGNAL_MESSAGE.SHARING_YOUR_SCREEN });
      };

      const onScreenShareEnd = () => {
        setIsSharing(false);
        logEvent(get(mixpanelEvents, 'MEETING_PARTICIPANT_STOPPED_SCREEN_SHARE'));
        addIrEventLog(ROOM_EVENT_NAMES?.SCREENSHARE_STOP);
        sendSignalling(
          IR_SIGNAL_TYPES.NOTIFICATION,
          `${participantDetails?.name} stopped sharing their screen`,
          'Candidate',
          participantDetails?.email,
        );
        sendSignalling(
          IR_SIGNAL_TYPES.NOTIFICATION,
          `${participantDetails?.name} stopped sharing their screen`,
          'Panel',
          participantDetails?.email,
        );
      };

      if (irVideoServiceProvider !== providerOptions.TWILIO) {
        room?.localParticipant
          ?.setScreenShareEnabled(true)
          .then((publication) => {
            stopScreenShareRef.current = () => {
              room?.localParticipant
                ?.setScreenShareEnabled(false)
                .then(() => onScreenShareEnd())
                .catch(onError);
            };
            publication?.track?.on(TrackEvent?.Ended, stopScreenShareRef.current);
            onScreenShareStart();
          })
          .catch((error) => {
            handleScreenSharingError(error, onError);
          });
      } else {
        navigator.mediaDevices
          .getDisplayMedia({
            audio: false,
            video: true,
          })
          .then((stream) => {
            const track = stream?.getTracks()[0];

            // All video tracks are published with 'low' priority. This works because the video
            // track that is displayed in the 'MainParticipant' component will have its priority
            // set to 'high' via track.setPriority()
            room?.localParticipant
              ?.publishTrack(track, {
                name: 'screen', // Tracks can be named to easily find them later
                priority: 'low', // Priority is set to high by the subscriber when the video track is rendered
              })
              .then((trackPublication) => {
                stopScreenShareRef.current = () => {
                  room?.localParticipant?.unpublishTrack(track);
                  // TODO: remove this if the SDK is updated to emit this event
                  room?.localParticipant?.emit('trackUnpublished', trackPublication);
                  track?.stop();
                  onScreenShareEnd();
                };

                track.onended = stopScreenShareRef.current;
                onScreenShareStart();
              })
              .catch(onError);
          })
          .catch((error) => {
            // Don't display an error if the user closes the screen share dialog
            handleScreenSharingError(error, onError);
          });
      }
    },
    [room, onError],
  );

  const toggleScreenShare = useCallback(
    (props) => {
      const { participantDetails, logEvent, addIrEventLog } = props || {};
      if (room) {
        if (!isSharing) {
          shareScreen({ participantDetails, logEvent, addIrEventLog });
        } else {
          stopScreenShareRef.current();
        }
      }
    },
    [isSharing, shareScreen, room, onError],
  );

  return [isSharing, toggleScreenShare, stopScreenShareRef];
}
