import { get, find } from 'lodash';

export const checkIfCandidateIsAlreadyEvaluated = ({ step, localParticipant }) =>
  !get(step, 'assessment_section.section.form.is_editable') &&
  get(
    find(get(step, 'panel_candidates', []), (panel) => {
      const panelUserEmail = get(panel, 'panel.user.email');
      const panelUserId = get(panel, 'panel.user.id');
      const localParticipantEmail = get(localParticipant, '0.user_details.email');
      const localParticipantId = get(localParticipant, '0.user_id');
      return panelUserEmail === localParticipantEmail || panelUserId === localParticipantId;
    }),
    'evaluations.0.recommendation',
    'hold',
  ) !== 'hold';

export const getEvaluatedFormData = ({ step, localParticipant }) =>
  get(
    find(get(step, 'panel_candidates', []), (panel) => {
      const panelUserEmail = get(panel, 'panel.user.email');
      const localParticipantEmail = get(localParticipant, '0.email') || get(localParticipant, '0.user_details.email');
      return panelUserEmail === localParticipantEmail;
    }),
    'evaluations[0].form_instances[0].data',
    [],
  );
