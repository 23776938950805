import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, join, map, trim } from 'lodash';
import { POPUP_MODAL_TYPE } from 'containers/InterviewRooms/constants';

const sizes = {
  xxl: 'h-36 w-36 text-5xl font-medium',
  xl: 'h-24 w-24 text-3xl font-medium',
  lg: 'h-16 w-16 text-2xl font-medium',
  l: 'h-14 w-14 text-xl font-medium',
  md: 'h-12 w-12 text-lg font-medium',
  sm: 'h-8 w-8 text-sm font-normal',
};

export function Avatar(props) {
  const { size = 'md', data = {}, highlightSpeaker, type } = props;
  const displayPicture = useMemo(() => data && get(data, 'profile_pic', ''), [data]);
  const name = trim(data?.name) || data?.email;
  const splitName = name?.split(' ');
  const initials = join(
    map(splitName, (value, index) => (index < 2 ? value?.[0]?.toUpperCase() || '' : null)),
    '',
  );

  if (!displayPicture) {
    return (
      <div className={classNames('group relative', get(sizes, size, sizes?.md))}>
        <div
          className={classNames(
            'inline-flex items-center justify-center rounded-full dark:bg-neutral-100',
            get(sizes, size, sizes?.md),
            highlightSpeaker && 'border-4 border-solid dark:border-blue-500 border-blue-600',
            type === POPUP_MODAL_TYPE.INVITE_PEOPLE ? 'bg-slate-600' : 'bg-slate-300',
          )}
        >
          <div
            className={classNames(
              'leading-none dark:text-neutral-900 font-normal flex justify-center items-center',
              type === POPUP_MODAL_TYPE.INVITE_PEOPLE ? 'text-white' : 'text-slate-800',
            )}
          >
            {initials}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('group relative', get(sizes, size, sizes?.md))}>
      <img
        className={classNames('inline-block rounded-full object-cover', get(sizes, size, sizes?.md))}
        src={displayPicture}
        alt="avatar"
      />
    </div>
  );
}

Avatar.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    middle_name: PropTypes.string,
    profile_pic: PropTypes.string,
  }),
  size: PropTypes.oneOf(['md', 'lg', 'xl', 'sm', 'xxl']),
  highlightSpeaker: PropTypes.bool,
};

export default memo(Avatar);
