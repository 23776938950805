export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const CANDIDATE_ROLE = 'CANDIDATE';
export const EVALUATOR_ROLE = 'EVALUATOR';
export const PARENT_FRAME_CONTAINER = {
  RECRUIT: 'RECRUIT',
  TOP: 'TOP',
  RECRUIT_IS: 'RECRUIT_IS',
};
export const ROLE_MAP = {
  1: 'Candidate',
  2: 'Evaluator',
  3: 'Organizer',
  GUEST: 'Candidate',
  PARTICIPANT: 'Evaluator',
  ORGANIZER: 'Organizer',
  Candidate: 'Candidate',
};
export const ANONYMOUS = 'Anonymous';

export const FIREBASE_COLLECTION_NAME = {
  IR_CHAT: 'IR_CHAT',
  PUBLIC_CHAT: 'ir_public_chat',
  PRIVATE_CHAT: 'ir_private_chat',
  CODE_JAM: 'ir_codejam',
};

export const FIREBASE_RECEIVER_TYPE = {
  CANDIDATE: 'CANDIDATE',
  PANEL: 'PANEL',
};
export const REQUEST_TYPE = {
  SECONDARY_CAMERA_REQUEST: 'SECONDARY_CAMERA_REQUEST',
  SECONDARY_CAMERA_REQUESTED: 'SECONDARY_CAMERA_REQUESTED',
  SECONDARY_CAMERA_ACCEPTED: 'SECONDARY_CAMERA_ACCEPTED',
  SECONDARY_CAMERA_DECLINED: 'SECONDARY_CAMERA_DECLINED',
  SECONDARY_CAMERA_CONNECTED: 'SECONDARY_CAMERA_CONNECTED',
  ENV_CHECK_REQUEST: 'ENV_CHECK_REQUEST',
  ENV_CHECK_REQUESTED: 'ENV_CHECK_REQUESTED',
  ENV_CHECK_ACCEPTED: 'ENV_CHECK_ACCEPTED',
  ENV_CHECK_DECLINED: 'ENV_CHECK_DECLINED',
  ENV_CHECK_CONNECTED: 'ENV_CHECK_CONNECTED',
  ENV_CHECK_PROGRESS: 'ENV_CHECK_PROGRESS',
  ENV_CHECK_COMPLETED: 'ENV_CHECK_COMPLETED',
};
export const REQUESTED_TYPE = [REQUEST_TYPE.SECONDARY_CAMERA_REQUESTED, REQUEST_TYPE.ENV_CHECK_REQUESTED];
export const ACCEPTED_TYPE = [REQUEST_TYPE.SECONDARY_CAMERA_ACCEPTED, REQUEST_TYPE.ENV_CHECK_ACCEPTED];
export const CONNECTED_TYPE = [REQUEST_TYPE.SECONDARY_CAMERA_CONNECTED, REQUEST_TYPE.ENV_CHECK_CONNECTED];

// Participants Default Name
export const DEFAULT_NAME = {
  CANDIDATE: 'Candidate',
  INTERVIEWER: 'Interviewer',
  SECONDARY_CAMERA: 'Secondary Camera',
};

export const LOW_FRAME_RATE_THRESHOLD = 11;

export const LANDING_PAGE_TYPES = {
  SECONDARY_CAMERA: 'SECONDARY_CAMERA',
  ENV_CHECK: 'ENV_CHECK',
};
