import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { addDoc, serverTimestamp, updateDoc, doc, arrayUnion } from 'firebase/firestore';
import { log } from 'utils/logger';

export default function useSignallingHook() {
  const { interviewRoomsRedirect, secondaryCamera } = useSelector((state) => state) || {};
  const signalRef = interviewRoomsRedirect?.signalRefData || secondaryCamera?.signalRefData;
  const participantRefData = interviewRoomsRedirect?.participantRefData;
  const secondaryCamParticipantRefData = secondaryCamera?.participantRefData;
  const voipStatusRefData = interviewRoomsRedirect?.voipStatusRefData;
  const secCamEnvCheckRefData = interviewRoomsRedirect?.secCamEnvCheckRefData || secondaryCamera?.secCamEnvCheckRefData;
  const objectDetectedRefData = interviewRoomsRedirect?.objectDetectedRefData || secondaryCamera?.objectDetectedRefData;

  const addDocument = useCallback(async (ref, data) => {
    try {
      await addDoc(ref, {
        created_at: serverTimestamp(),
        ...data,
      });
    } catch (error) {
      log('Firebase Error: ', error);
    }
  }, []);

  const sendSignalling = useCallback(
    async (type, message, to, from, message_type, notification_type) => {
      const data = {
        sent_at: serverTimestamp(),
        created_by: from || null,
        is_deleted: false,
        reciever: to || null,
        sender: from || null,
        signal_message: message || null,
        signal_type: type || null,
        message_type: message_type || 'default',
        acknowledged: [],
        notification_type: notification_type || 'info',
      };
      await addDocument(signalRef, data);
    },
    [signalRef],
  );

  const updateSignalling = useCallback((signalRefData, id, participantId) => {
    const newRef = doc(signalRefData, id);
    updateDoc(newRef, {
      acknowledged: arrayUnion(Number(participantId)),
    }).catch((error) => log('Firebase Error: ', error));
  }, []);

  const addDocToFirestore = useCallback(
    ({
      meetingStatus,
      meetingParticipantId,
      participantEmail,
      participantName,
      participantRole,
      videoServiceProviders,
      recordingStatus,
      currentTab,
      deviceType,
    }) => {
      (async () => {
        await addDoc(participantRefData || secondaryCamParticipantRefData, {
          created_at: serverTimestamp(),
          id: meetingParticipantId || '',
          email: participantEmail || '',
          name: participantName || '',
          meeting_participant_id: meetingParticipantId || '',
          in_meeting: meetingStatus,
          role: participantRole || '',
          fallback_video_service_providers: videoServiceProviders || '',
          recording_status: typeof recordingStatus === 'boolean' ? recordingStatus : null,
          current_tab: currentTab || null,
          device_type: deviceType || null,
        }).catch((error) => log('Firebase Error: ', error));
      })();
    },
    [participantRefData, secondaryCamParticipantRefData],
  );

  const addVoipCallStatus = useCallback(
    ({ status, meetingParticipantId, participantEmail, participantName }) => {
      (async () => {
        await addDoc(voipStatusRefData, {
          created_at: serverTimestamp(),
          id: meetingParticipantId || '',
          email: participantEmail || '',
          name: participantName || '',
          status: status || null,
        }).catch((error) => log('Firebase VoIP Status Error: ', error));
      })();
    },
    [voipStatusRefData],
  );

  const addSecCamEnvCheckState = useCallback(
    async ({ type, message, to, from, message_type = 'default', notification_type = 'info' }) => {
      const data = {
        created_by: from || null,
        reciever: to || null,
        sender: from || null,
        signal_message: message || null,
        signal_type: type || null,
        message_type,
        notification_type,
      };
      await addDocument(secCamEnvCheckRefData, data);
    },
    [secCamEnvCheckRefData],
  );

  const addSecCamObjectDetectionState = useCallback(
    async ({ from, objects_detected_status = null }) => {
      const data = {
        created_by: from || null,
        sender: from || null,
        objects_detected_status,
      };
      await addDocument(objectDetectedRefData, data);
    },
    [objectDetectedRefData],
  );

  return [
    sendSignalling,
    updateSignalling,
    addDocToFirestore,
    addVoipCallStatus,
    addSecCamEnvCheckState,
    addSecCamObjectDetectionState,
  ];
}
