import { capitalize, find, lowerCase } from 'lodash';

export const recommendationStatusTheme = {
  default: {
    bg: 'dark:bg-neutral-700 bg-slate-300',
    text: 'dark:text-neutral-100 text-slate-500',
    padding: 'px-3 py-1',
    borderRadius: 'rounded-xl',
  },
  not_invited: {
    bg: 'dark:bg-gray-600 bg-gray-200',
    text: 'dark:text-gray-200 text-gray-600',
    padding: 'px-3 py-1',
    borderRadius: 'rounded-xl',
  },
  completed: {
    bg: 'dark:bg-blue-900 bg-blue-50',
    text: 'dark:text-blue-50 text-blue-900',
    padding: 'px-3 py-1',
    borderRadius: 'rounded-xl',
  },
  in_progress: {
    bg: 'dark:bg-orange-700 bg-orange-100',
    text: 'dark:text-orange-100 text-orange-700',
    padding: 'px-3 py-1',
    borderRadius: 'rounded-xl',
  },
  invited: {
    bg: 'dark:bg-gray-700 bg-gray-200',
    text: 'dark:text-gray-200 text-gray-700',
    padding: 'px-3 py-1',
    borderRadius: 'rounded-xl',
  },
  selected: {
    bg: 'dark:bg-green-600 bg-green-50',
    text: 'dark:text-green-50 text-green-600',
    padding: 'px-3 py-1',
    borderRadius: 'rounded-xl',
  },
  scheduled: {
    bg: 'dark:bg-purple-800 bg-purple-50',
    text: 'dark:text-purple-50 text-purple-800',
    padding: 'px-3 py-1',
    borderRadius: 'rounded-xl',
  },
  cancelled: {
    bg: 'dark:bg-red-600 bg-red-50',
    text: 'dark:text-red-50 text-red-600',
    padding: 'px-3 py-1',
    borderRadius: 'rounded-xl',
  },
  no_show: {
    bg: 'dark:bg-orange-600 bg-orange-50',
    text: 'dark:text-orange-50 text-orange-600',
    padding: 'px-3 py-1',
    borderRadius: 'rounded-xl',
  },
  draft: {
    bg: 'dark:bg-gray-900 bg-gray-50',
    text: 'dark:text-gray-50 text-gray-900',
    padding: 'px-3 py-1',
    borderRadius: 'rounded-xl',
  },
  on_hold: {
    bg: 'dark:bg-yellow-800 bg-yellow-50',
    text: 'dark:text-yellow-50 text-yellow-800',
    padding: 'px-3 py-1',
    borderRadius: 'rounded-xl',
  },
  live: {
    bg: 'bg-transparent',
    text: 'dark:text-red-500 text-red-600',
    border: 'border border-solid dark:border-red-500 border-red-600',
    padding: 'px-3 py-1',
    borderRadius: 'rounded-xl',
  },
  not_matched: {
    bg: 'bg-transparent',
    text: 'dark:text-red-500 text-red-600 ',
    border: 'border border-solid dark:border-red-500 border-red-600 ',
    borderRadius: 'rounded-lg',
    padding: 'px-4 py-2',
  },
  matched: {
    bg: 'bg-transparent',
    text: 'dark:text-green-500 text-green-600 ',
    border: 'border border-solid dark:border-green-500 border-green-600 ',
    borderRadius: 'rounded-lg',
    padding: 'px-4 py-2',
  },
  not_generated: {
    bg: 'bg-transparent',
    text: 'dark:text-neutral-400 text-slate-400 ',
    border: 'border border-solid dark:border-neutral-400 border-slate-400',
    borderRadius: 'rounded-lg',
    padding: 'px-4 py-2',
  },
  generating: {
    bg: 'bg-transparent',
    text: 'text-neutral-50 text-slate-900',
    border: 'border border-solid dark:border-neutral-50 border-slate-900',
    borderRadius: 'rounded-lg',
    padding: 'px-4 py-2',
  },
  easy: {
    padding: 'py-0.5 px-2',
    text: 'dark:text-green-400 text-green-500',
    bg: 'transparent',
    border: 'border border-solid dark:border-green-600 border-green-500 ',
    borderRadius: 'rounded-md',
  },
  medium: {
    padding: 'py-0.5 px-2',
    text: 'dark:text-yellow-400 text-yellow-500',
    bg: 'transparent',
    border: 'border border-solid dark:border-yellow-600 border-yellow-500 ',
    borderRadius: 'rounded-md',
  },
  advanced: {
    padding: 'py-0.5 px-2',
    text: 'dark:text-red-400 text-red-500',
    bg: 'transparent',
    border: 'border border-solid dark:border-red-600 border-red-500 ',
    borderRadius: 'rounded-md',
  },
  pending: {
    text: 'dark:text-neutral-400 text-slate-400 ',
  },
  accepted: {
    text: 'dark:text-green-600 text-green-700',
  },
  declined: {
    text: 'dark:text-red-600 text-red-700',
  },
  microphone: {
    bg: 'dark:bg-neutral-900 bg-slate-50 ',
    text: 'dark:text-neutral-50 text-black text-lg',
    border: 'border dark:border-neutral-700 border-slate-300',
    padding: 'px-4 py-0.5',
    borderRadius: 'rounded-lg',
  },
  passed: {
    bg: 'bg-transparent',
    text: 'text-green-600',
    border: 'border border-solid border-green-600',
    borderRadius: 'rounded-lg',
    padding: 'px-3 py-1.5',
    height: 'max-h-9',
  },
  failed: {
    bg: 'bg-transparent',
    text: 'text-red-600',
    border: 'border border-solid border-red-600',
    borderRadius: 'rounded-lg',
    padding: 'px-3 py-1.5',
    height: 'max-h-9',
  },
  verification_pending: {
    bg: 'bg-transparent',
    text: 'text-yellow-500',
    border: 'border border-solid border-yellow-500',
    borderRadius: 'rounded-lg',
    padding: 'px-3 py-1.5',
    height: 'max-h-9',
  },
};

export const recommendationStatus = {
  DEFAULT: 'DEFAULT',
  NOT_INVITED: 'NOT_INVITED',
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  INVITED: 'INVITED',
  SELECTED: 'SELECTED',
  SCHEDULED: 'SCHEDULED',
  CANCELLED: 'CANCELLED',
  NO_SHOW: 'NO_SHOW',
  DRAFT: 'DRAFT',
  ON_HOLD: 'ON_HOLD',
  LIVE: 'LIVE',
  NOT_MATCHED: 'NOT_MATCHED',
  MATCHED: 'MATCHED',
  NOT_GENERATED: 'NOT_GENERATED',
  GENERATING: 'GENERATING',
  EASY: 'EASY',
  MEDIUM: 'MEDIUM',
  ADVANCED: 'ADVANCED',
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  MICROPHONE_ON: 'MICROPHONE_ON',
  MICROPHONE_OFF: 'MICROPHONE_OFF',
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  VERIFICATION_PENDING: 'VERIFICATION_PENDING',
};

export const recommendationStatusList = [
  {
    type: ['recommendation_status'],
    key: recommendationStatus.DEFAULT,
    label: 'Default',
    theme: recommendationStatusTheme.default,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.NOT_INVITED,
    label: 'Not Invited',
    theme: recommendationStatusTheme.not_invited,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.COMPLETED,
    label: 'Completed',
    theme: recommendationStatusTheme.completed,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.IN_PROGRESS,
    label: 'In Progress',
    theme: recommendationStatusTheme.in_progress,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.INVITED,
    label: 'Invited',
    theme: recommendationStatusTheme.invited,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.SELECTED,
    label: 'Selected',
    theme: recommendationStatusTheme.selected,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.SCHEDULED,
    label: 'Scheduled',
    theme: recommendationStatusTheme.scheduled,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.CANCELLED,
    label: 'Cancelled',
    theme: recommendationStatusTheme.cancelled,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.NO_SHOW,
    label: 'No Show',
    theme: recommendationStatusTheme.no_show,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.DRAFT,
    label: 'Draft',
    theme: recommendationStatusTheme.draft,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.ON_HOLD,
    label: 'On Hold',
    theme: recommendationStatusTheme.on_hold,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.LIVE,
    label: 'Live',
    theme: recommendationStatusTheme.live,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.NOT_MATCHED,
    label: 'Not Matched',
    theme: recommendationStatusTheme.not_matched,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.MATCHED,
    label: 'Matched',
    theme: recommendationStatusTheme.matched,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.NOT_GENERATED,
    label: 'Not Generated',
    theme: recommendationStatusTheme.not_generated,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.GENERATING,
    label: 'Generating...',
    theme: recommendationStatusTheme.generating,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.EASY,
    label: 'Easy',
    theme: recommendationStatusTheme.easy,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.MEDIUM,
    label: 'Medium',
    theme: recommendationStatusTheme.medium,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.ADVANCED,
    label: 'Advanced',
    theme: recommendationStatusTheme.advanced,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.PENDING,
    label: 'Pending',
    theme: recommendationStatusTheme.pending,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.ACCEPTED,
    label: 'Accepted',
    theme: recommendationStatusTheme.accepted,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.DECLINED,
    label: 'Declined',
    theme: recommendationStatusTheme.declined,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.MICROPHONE_ON,
    label: 'Microphone On',
    theme: recommendationStatusTheme.microphone,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.MICROPHONE_OFF,
    label: 'Microphone Off',
    theme: recommendationStatusTheme.microphone,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.PASSED,
    label: 'Passed',
    theme: recommendationStatusTheme.passed,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.FAILED,
    label: 'Failed',
    theme: recommendationStatusTheme.failed,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.VERIFICATION_PENDING,
    label: 'Pending...',
    theme: recommendationStatusTheme.verification_pending,
  },
];

export const getFormattedRecommendationStatus = (status) => {
  if (status && (typeof status === 'string' || Object.keys(status).length)) {
    if (status?.key && status?.theme && status?.theme?.bg) {
      return status;
    }
    const parsedStatus = status && typeof status === 'string' ? status : status?.recommendationStatus;
    const variant = find(
      recommendationStatusList,
      (s) => lowerCase(s.key) === lowerCase((parsedStatus || '').trim() || ''),
    );
    if (variant && variant?.label) {
      return variant;
    }
    return parsedStatus ? { ...recommendationStatusList[0], key: parsedStatus, label: capitalize(parsedStatus) } : null;
  }
  return status;
};
