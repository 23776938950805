import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { useDispatch, useSelector } from 'react-redux';
import { last, isEmpty, get } from 'lodash';
import { isMobile } from 'utils/media';
import { mixpanelEvents } from 'utils/mixpanelEvents';
import EnvConfig from 'utils/envConfig';
import { growthBookFeatureFlags } from 'utils/featureFlags';
import useSignallingHook from 'hooks/useSignalling';
import { useEventLogger } from 'hooks/useEventLogger';
import { providerOptions } from 'contexts/VideoProvider/constants';
import VideoServiceInterface from 'containers/IRRedirect/videoServiceInterface';
import { GLOBAL_ERROR } from 'containers/App/constants';
import { participantDataState } from 'containers/InterviewRooms/selector';
import { fetchFallbackProviders, getValidProvider } from 'containers/InterviewRooms/utils';
import { participantDetailsState } from 'containers/SecondaryCamera/selector';
import useProviderService from './useProviderService';

export default function useRoom({
  localTracks,
  onError = () => {},
  options = {},
  setFallbackProviderContext,
  fallbackProviderContext,
}) {
  const dispatch = useDispatch();
  // TODO move gb flags to parent container
  const noiceCancellationEnabled = useFeatureValue(growthBookFeatureFlags.NOICE_CANCELLATION);
  const vp9Enabled = useFeatureValue(growthBookFeatureFlags.IR_VIDEO_VP9);
  const videoCaptureDefaultResolution = useFeatureValue(growthBookFeatureFlags.IR_VIDEO_CAPTURE_DEFAULT_RESOLUTION);
  const videoServiceProvidersPriority = useFeatureValue(growthBookFeatureFlags.IR_VIDEO_SERVICE_PROVIDER_PRIORITY);

  const [room, setRoom] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const optionsRef = useRef(options);
  const env_config = EnvConfig.fetchEnvObj();
  const liveKitUrl = env_config.LIVEKIT_URL;
  const liveKitHostedUrl = env_config.LIVEKIT_SELF_HOSTED_URL;
  const videoService = VideoServiceInterface();
  const [, , addDocToFirestore] = useSignallingHook();
  const participantData = useSelector(participantDataState);
  const secondaryParticipantsData = useSelector(participantDetailsState);
  const { getProviderService } = useProviderService();
  const { fallback_video_service_providers } = useMemo(
    () => fetchFallbackProviders(participantData || secondaryParticipantsData),
    [participantData, secondaryParticipantsData],
  );
  const logEvent = useEventLogger();

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options;
  }, [options]);

  const disconnect = () => room.disconnect();

  const updateFirebaseDoc = () => {
    // Update the firebase doc list with the new fallback provider context. This is done to ensure that the
    // retry for remote participants is with latest context value and prevent retry of already failed providers.
    if (
      (isEmpty(fallback_video_service_providers) && !isEmpty(fallbackProviderContext)) ||
      last(fallback_video_service_providers) !== last(fallbackProviderContext)
    )
      addDocToFirestore({
        videoServiceProviders: [...fallback_video_service_providers, ...fallbackProviderContext],
        meetingStatus: null,
      });
    // Reset the fallbackProviderContext after updating the firebase doc to prevent retry with same, when remote
    // participants fallbacks to different provider.
    setFallbackProviderContext([]);
  };

  const updateFallbackContext = (initialVideoProvider, error) => {
    // Get the first valid provider not in the exclusions list and add it to the fallbackProviderContext
    const validProvider = getValidProvider(
      fallbackProviderContext,
      fallback_video_service_providers,
      initialVideoProvider,
      videoServiceProvidersPriority,
    );
    if (validProvider) {
      setFallbackProviderContext([...fallbackProviderContext, initialVideoProvider, validProvider]);
      throw error;
    } else dispatch({ type: GLOBAL_ERROR, error });
  };

  const connect = useCallback(
    ({ token, provider, audioVideoTracks }) => {
      room?.disconnect();
      setRoom(null);
      setIsConnecting(true);
      const initialVideoProvider = provider || videoService.irVideoServiceProvider;
      const tracks = audioVideoTracks || localTracks;
      const providerUrl = initialVideoProvider === providerOptions.LIVEKIT ? liveKitUrl : liveKitHostedUrl;
      let providerValue = 'twilio';
      if (
        initialVideoProvider === providerOptions.LIVEKIT ||
        initialVideoProvider === providerOptions.LIVEKIT_SELF_HOSTED
      ) {
        providerValue = 'livekit';
      }
      const providerService = getProviderService(providerValue);
      if (!providerService) {
        const error = new Error(`Provider service not found for the key: ${providerValue}`);
        onError(error);
        Sentry.captureException(error);
      }
      const { connectProvider } = providerService();
      return connectProvider({
        optionsRef: optionsRef.current,
        url: providerUrl,
        vp9Enabled,
        videoCaptureDefaultResolution,
        providerToken: token,
        tracks,
        onDisconnect: disconnectRoom,
        noiceCancellationEnabled,
        onSuccess: roomConnectSuccess,
        onError: (e) => roomConnectError(e, initialVideoProvider),
      });
    },
    [videoService, onError, localTracks],
  );

  const roomConnectSuccess = (roomObj) => {
    setRoom(roomObj);
    setIsConnecting(false);
    // Add a listener to disconnect from the room when a user closes their browser
    window.addEventListener('beforeunload', () => disconnect);
    if (isMobile) {
      // Add a listener to disconnect from the room when a mobile user closes their browser
      window.addEventListener('pagehide', () => disconnect);
    }
    updateFirebaseDoc();
  };

  const roomConnectError = (e, initialVideoProvider) => {
    onError(e);
    Sentry.captureException(e);
    setIsConnecting(false);
    updateFallbackContext(initialVideoProvider, e);
  };

  const disconnectRoom = ({ tracks }) => {
    logEvent(get(mixpanelEvents, 'ROOM_DISCONNECTED'));
    tracks?.forEach((track) => track?.mediaStreamTrack?.stop());
    setTimeout(() => setRoom(null));
    window.removeEventListener('beforeunload', disconnect);
    if (isMobile) {
      window.removeEventListener('pagehide', disconnect);
    }
  };

  return { room, isConnecting, connect };
}
