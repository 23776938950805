import produce from 'immer';
import {
  COMMENT_UPDATE_ACTION,
  EVALUATION_SAVE_ACTION,
  EVALUATION_SAVE_ACTION_COMPLETE,
  FETCH_EVALUATION_DETAILS,
  FETCH_EVALUATION_DETAILS_COMPLETE,
  RATING_UPDATE_ACTION,
  RECOMMENDATION_ACTION,
} from 'containers/Evaluation/constants';

export const initialState = {
  success: null,
  error: null,
  isLoading: false,
  payload: {},
  evaluation: null,
  step: {},
  id: null,
  saveLoading: false,
  recommendation: null,
  files: [],
  formData: {},
  rating: [],
  evaluationComment: '',
  evaluationComplete: false,
  errorOnSubmit: false,
};

/* eslint-disable default-case, no-param-reassign */
const evaluationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_EVALUATION_DETAILS:
        draft.isLoading = true;
        draft.payload = {};
        draft.recommendation = null;
        break;
      case FETCH_EVALUATION_DETAILS_COMPLETE:
        draft.isLoading = false;
        draft.step = action.step;
        draft.evaluation = action.evaluation;
        draft.error = action.error;
        draft.id = action.id;
        break;
      case EVALUATION_SAVE_ACTION_COMPLETE:
        draft.saveLoading = false;
        draft.recommendation = action.recommendation;
        draft.evaluationComplete = true;
        draft.errorOnSubmit = action.error;
        break;
      case EVALUATION_SAVE_ACTION:
        draft.recommendation = action.recommendationOption || draft.recommendation;
        draft.formData = action.formData;
        draft.files = action.files;
        draft.saveLoading = true;
        draft.errorOnSubmit = false;
        break;
      case RATING_UPDATE_ACTION:
        draft.rating = action.rating;
        break;
      case RECOMMENDATION_ACTION:
        draft.recommendation = action.recommendation;
        break;
      case COMMENT_UPDATE_ACTION:
        draft.evaluationComment = action.evaluationComment;
        break;
    }
  });

export default evaluationReducer;
