import LivekitService from 'services/Livekit';
import TwilioService from 'services/Twilio';

const providerMap = {
  livekit: LivekitService,
  twilio: TwilioService,
};

const useProviderService = () => {
  const getProviderService = (provider) => providerMap[provider];

  return {
    getProviderService,
  };
};

export default useProviderService;
