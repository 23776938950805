export const GLOBAL_ERROR = 'app/containers/SecondaryCamera/GLOBAL_ERROR';
export const FETCH_TOKEN = 'app/containers/SecondaryCamera/FETCH_TOKEN';
export const UPDATE_CUSTOM_TOKEN = 'app/containers/SecondaryCamera/UPDATE_CUSTOM_TOKEN';
export const JOIN_ROOM_COMPLETE = 'app/containers/SecondaryCamera/JOIN_ROOM_COMPLETE';
export const SET_VIDEO_TRACK = 'app/containers/SecondaryCamera/SET_VIDEO_TRACK';
export const FETCH_USER_DETAILS_COMPLETE = 'app/containers/SecondaryCamera/FETCH_USER_DETAILS_COMPLETE';
export const SET_IS_CONNECTING = 'app/containers/SecondaryCamera/SET_IS_CONNECTING';
export const SET_SHOW_POPUP = 'app/containers/SecondaryCamera/SET_SHOW_POPUP';
export const SET_IS_RECONNECTING = 'app/containers/SecondaryCamera/SET_IS_RECONNECTING';
export const SET_BROWSER_PERMISSION_DENIED = 'app/containers/SecondaryCamera/SET_BROWSER_PERMISSION_DENIED';
export const SET_PARTICIPANT_DATA = 'app/containers/SecondaryCamera/SET_PARTICIPANT_DATA';
export const SET_PARTICIPANT_REF_DATA = 'app/containers/SecondaryCamera/SET_PARTICIPANT_REF_DATA';
export const SET_SEC_CAM_ENV_CHECK_REF_DATA = 'app/containers/SecondaryCamera/SET_SEC_CAM_ENV_CHECK_REF_DATA';
export const SET_OBJECT_DETECTED_REF_DATA = 'app/containers/SecondaryCamera/SET_OBJECT_DETECTED_REF_DATA';
export const SET_DEFAULT_PROVIDER = 'app/containers/SecondaryCamera/SET_DEFAULT_PROVIDER';
export const SET_JOIN_BTN_CLICKED = 'app/containers/SecondaryCamera/SET_JOIN_BTN_CLICKED';
export const JOIN_INTERVIEW_ROOMS = 'app/containers/SecondaryCamera/JOIN_INTERVIEW_ROOMS';
export const SET_SEC_CAM_CONNECTED = 'app/containers/SecondaryCamera/SET_SEC_CAM_CONNECTED';
export const SET_OBJECTS_DETECTION_STATE = 'app/containers/SecondaryCamera/SET_OBJECTS_DETECTION_STATE';
export const SET_SIGNAL_REF_DATA = 'app/containers/SecondaryCamera/SET_SIGNAL_REF_DATA';

export const CAMERA = 'camera';
export const LIGHT = 'light';
export const LANDSCAPE = 'landscape';
export const PORTRAIT = 'portrait';
export const SECONDARY_CAMERA = 'secondaryCamera';
export const PRIMARY_DISCONNECTED = 'primaryDisconnected';
export const CONNECTION_UNSTABLE = 'connectionUnstable';

export const secCamAlertContent = {
  landscape: {
    title: 'Position yourself perfectly!',
    titleTag: 'Please adjust your phone camera to capture both your face and your laptop screen clearly.',
    signalMessageType: 'SECONDARY_CAMERA_LANDSCAPE_INSTRUCTIONS',
    buttonContent: 'OK',
  },
  portrait: {
    title: 'Rotate your phone',
    titleTag: 'Rotate your smartphone to horizontal position',
    signalMessageType: 'SECONDARY_CAMERA_VERTICAL_INSTRUCTIONS',
    noButton: true,
  },
  primaryDisconnected: {
    title: 'Primary camera disconnected',
    titleTag:
      'The primary camera has lost connection and exited the interview. Please check your internet connection and try reconnecting.',
    signalMessageType: 'PRIMARY_CAMERA_DISCONNECTED',
    buttonContent: 'Exit',
  },
  connectionUnstable: {
    title: 'Trying to reconnect...',
    titleTag:
      'Please stay connected until the connection is restored. You can continue as normal once the internet connection is stable.',
    noButton: true,
    isLoading: true,
  },
};

export const detectableObjectTypes = ['laptop', 'person'];

export const objectsTypes = {
  laptop: 'Screen',
  person: 'Person',
};
