/**
 *
 * ExternalFormFrame
 *
 */

import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import processEvent from 'containers/Evaluation/processEvent';
import { get } from 'lodash';

export function EvaluationForm({ form = {}, submit, toggleSubmit, save, step }) {
  const token = get(JSON.parse(localStorage.getItem('tv.cp.recruit')), 'token');
  const url = `${form?.custom_url}?formId=${form?.id}&assc=${step?.id}&accessToken=${token}`;

  const ref = useRef();
  const Auth = {};

  useEffect(() => {
    if (submit) {
      if (ref.current) {
        ref.current.contentWindow.postMessage(
          {
            type: 'submitEvaluationForm',
          },
          '*',
        );
      }
      toggleSubmit(false);
    }
  }, [submit]);

  useEffect(() => {
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const e = window[eventMethod];
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
    e(messageEvent, (event) => processEvent(save, event, ref, Auth), false);
  }, []);

  return (
    <div className="h-full">
      <iframe
        ref={ref}
        src={url}
        title="form"
        className="bg-white"
        style={{
          height: '100%',
          width: '100%',
          border: 'none',
        }}
      />
    </div>
  );
}

EvaluationForm.propTypes = {
  form: PropTypes.shape({
    custom_url: PropTypes.string,
  }),
};

export default memo(EvaluationForm);
