import React, { createContext, useEffect, useMemo, useState } from 'react';
import { getItem, setItem } from 'utils/store';

export const DarkModeContext = createContext();

export const setTheme = (darkMode) => {
  if (darkMode) {
    setItem('IRTheme', 'dark');
    document.documentElement.classList.add('dark');
  } else {
    setItem('IRTheme', 'light');
    document.documentElement.classList.remove('dark');
  }
};

export function DarkModeProvider(props) {
  const storedTheme = getItem('IRTheme') || 'system default';
  const prefersDarkMode = window?.matchMedia('(prefers-color-scheme: dark)').matches;
  const [selectedTheme, setSelectedTheme] = useState(storedTheme);
  const [darkMode, setDarkMode] = useState(selectedTheme === 'dark' || prefersDarkMode);
  const themeContextValue = useMemo(
    () => ({
      darkMode,
      setDarkMode,
      setSelectedTheme,
      selectedTheme,
    }),
    [darkMode, setDarkMode, selectedTheme, setSelectedTheme],
  );

  useEffect(() => {
    setTheme(darkMode);
  }, [darkMode]);

  useEffect(() => {
    if (selectedTheme === 'system default') {
      setDarkMode(prefersDarkMode);
    } else {
      setDarkMode(selectedTheme === 'dark');
    }
  }, [selectedTheme, prefersDarkMode]);

  // Listen for changes to the browser's theme preference and update the theme accordingly
  useEffect(() => {
    const mediaQuery = window?.matchMedia('(prefers-color-scheme: dark)');
    const listener = (event) => {
      setDarkMode(event.matches);
    };

    mediaQuery.addEventListener('change', listener);

    return () => {
      mediaQuery.removeEventListener('change', listener);
    };
  }, []);

  return <DarkModeContext.Provider value={themeContextValue}>{props?.children}</DarkModeContext.Provider>;
}
