import { memoize, range, reduce, min, max, get, intersection, difference } from 'lodash';
import * as sentry from '@sentry/react';
import { customAlphabet } from 'nanoid';

const roleMap = ['MASTER_RECRUITER', 'RECRUITER', 'EVALUATOR'];

export function formatBytes(bytes, decimals = 0) {
  if (bytes === 0) return '0 Bytes';
  if (bytes === null) return '--';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export const getValueFromCharacters = memoize((value) => {
  const characters = value?.toString().split('');
  return reduce(characters, (acc, s) => acc + s.toLowerCase().charCodeAt(0) - 97 + 1, 0);
});

export function pageNumbers({ pages, current }) {
  if (current === 1) {
    return range(1, min([pages, 3]) + 1);
  }

  if (current === pages) {
    return range(max([1, pages - 2]), pages + 1);
  }

  const startPage = max([1, current - 1]);
  const endPage = min([pages, current + 1]);

  return range(startPage, endPage + 1);
}

export const parseJwt = (token) => {
  try {
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0)?.toString(16)}`.slice(-2)}`)
          .join(''),
      );
      return JSON.parse(jsonPayload);
    }
    return {};
  } catch {
    return {};
  }
};

export const processToken = (access_token) => {
  const user = parseJwt(access_token);
  const role_list = get(user, "['https://hasura.io/jwt/claims']['X-Hasura-Allowed-Roles']", []);
  const roles = [...intersection(roleMap, role_list), ...difference(role_list, roleMap)];
  const default_role = get(roles, '[0]', null);
  const user_id = get(user, "['https://hasura.io/jwt/claims']['X-Hasura-User-Id']", null);
  const tenant_id = Number(get(user, "['https://hasura.io/jwt/claims']['X-Hasura-Tenant-Id']", null));
  const processed_obj = { access_token, user, default_role, roles, user_id, tenant_id };
  localStorage.setItem('tv.cp', JSON.stringify({ ...JSON.parse(localStorage.getItem('tv.cp')), ...processed_obj }));
  return processed_obj;
};

export const processRecruitToken = (access_token, token) => {
  const user = parseJwt(access_token);
  const rest_token = user['rest-token'];
  const role = user['https://hasura.io/jwt/claims']['x-hasura-default-role'];
  const expires = user.exp;
  localStorage.setItem('tv.cp.recruit', JSON.stringify({ access_token, user, rest_token, role, expires, token }));
  return user;
};

export const downloadFile = (url, filename) => {
  fetch(url)
    .then((response) => {
      response
        .blob()
        .then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);

          // Setting various property values
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = filename || 'sample.pdf';
          alink.click();
          window.URL.revokeObjectURL(fileURL);
        })
        .catch((err) => {
          sentry.captureException(err);
        });
    })
    .catch((err) => {
      sentry.captureException(err);
    });
};

export const createCookie = (name, value, minutes) => {
  try {
    let expires = '';
    if (minutes) {
      const date = new Date();
      date.setTime(date.getTime() + minutes * 60 * 1000);
      expires = `; expires=${date.toGMTString()}`;
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
  } catch (error) {
    sentry.captureException(error);
  }
};

export const readCookie = (name) => {
  try {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  } catch (error) {
    sentry.captureException(error);
    return null;
  }
};

export const getTraceId = () => {
  const nanoid = customAlphabet('0123456789abcdef', 32); // Sentry expects 32 char hexadecimal string
  return nanoid();
};

export const ping = ({ url, timeout }) =>
  new Promise((resolve) => {
    const isOnline = () => resolve(true);
    const isOffline = () => resolve(false);

    // Append a timestamp to the URL to avoid caching
    const uniqueUrl = `${url}?_=${new Date().getTime()}`;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', uniqueUrl, true);

    /// Set no cache headers after opening the request
    xhr.setRequestHeader('Cache-Control', 'no-cache'); // This header may be ignored by some browsers for GET requests
    xhr.setRequestHeader('Pragma', 'no-cache'); // This header is mostly used for backward compatibility

    xhr.onerror = isOffline;
    xhr.ontimeout = isOffline;
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.HEADERS_RECEIVED) {
        if (xhr.status) isOnline();
        else isOffline();
      }
    };

    xhr.timeout = timeout;
    xhr.send();
  });
