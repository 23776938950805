import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { find } from 'lodash';
import { err } from 'utils/logger';
import useActiveSinkId from 'hooks/useActiveSinkId';
import useRoomReconnection from 'hooks/useRoomReconnection';
import useMediaStreamTrack from 'hooks/useMediaStreamTrack';
import { setSelfNotificationDataAction } from 'containers/IRRedirect/actions';
import { IR_MESSAGE_TYPES, IR_SIGNAL_SOURCE, IR_SIGNAL_TYPES, NOTIFICATION_TYPE } from 'utils/signalConstants';
import { viewMap } from 'containers/InterviewRooms/constants';
import {
  useLocalTracks,
  useRoom,
  useScreenShareToggle,
  useHandleRoomDisconnection,
  useHandleTrackPublicationFailed,
  useRestartTrackOnDeviceChange,
  useLocalVideoToggle,
  useLocalAudioToggle,
} from './hooks';
import { SelectedParticipantProvider } from './hooks/useSelectedParticipant';
import AttachVisibilityHandler from './hooks/AttachVisiblityHandler';

export const VideoContext = createContext({
  room: null,
  localTracks: [],
  mediaStream: [],
  isConnecting: false,
  reconnected: false,
  reconnecting: false,
  connect: () => {},
  onError: () => {},
  getLocalVideoTrack: () => {},
  getLocalAudioTrack: () => {},
  isLocalTracksAcquired: false,
  removeLocalVideoTrack: () => {},
  isSharingScreen: false,
  toggleScreenShare: () => {},
  isVideoEnabled: false,
  isAudioEnabled: false,
  toggleVideoEnabled: () => {},
  toggleAudioEnabled: () => {},
  getAudioAndVideoTracks: () => {},
  getAudioVideoStream: () => {},
  activeSinkId: null,
  setActiveSinkId: () => {},
  audioDevice: null,
  setFallbackProviderContext: () => {},
  fallbackProviderContext: [],
  localConnectedTracks: [],
  tracksAcquiringError: null,
});

export function VideoProvider({ options, children, onError }) {
  const onErrorCallback = useCallback(
    (error) => {
      // eslint-disable-next-line no-console
      err(`ERROR: ${error.message}`, error);
      if (onError) onError(error);
    },
    [onError],
  );

  const [fallbackProviderContext, setFallbackProviderContext] = useState([]);
  const [videoView, setVideoView] = useState(viewMap.DEFAULT);

  const dispatch = useDispatch();

  const sendSelfNotification = ({ signalMessage, isError }) => {
    const message_type = isError ? IR_MESSAGE_TYPES.DEFAULT : 'default';
    const notification_type = isError ? NOTIFICATION_TYPE.WARNING : NOTIFICATION_TYPE.INFO;
    dispatch(
      setSelfNotificationDataAction({
        signal_type: IR_SIGNAL_TYPES.NOTIFICATION,
        signal_message: signalMessage,
        message_type,
        notification_type,
        source: IR_SIGNAL_SOURCE.SELF,
        id: Math.random(),
      }),
    );
  };

  const handleAudioVideoPermissionError = ({ signalMessage }) => {
    if (signalMessage) sendSelfNotification({ signalMessage, isError: true });
  };

  const handleScreenSharingError = ({ signalMessage }) => {
    if (signalMessage) sendSelfNotification({ signalMessage, isError: true });
  };

  const handleScreenSharingSuccess = ({ signalMessage }) => {
    if (signalMessage) sendSelfNotification({ signalMessage });
  };

  const handleVideoToggleError = ({ signalMessage }) => {
    if (signalMessage) sendSelfNotification({ signalMessage, isError: true });
  };

  const handleAudioToggleError = ({ signalMessage }) => {
    if (signalMessage) sendSelfNotification({ signalMessage, isError: true });
  };

  const {
    localTracks,
    mediaStream,
    getLocalVideoTrack,
    isLocalTracksAcquired,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
    getAudioVideoStream,
    browserPermissions,
    localConnectedTracks,
    tracksAcquiringError,
  } = useLocalTracks({ onError: handleAudioVideoPermissionError });

  const { room, isConnecting, connect } = useRoom({
    localTracks,
    onError: onErrorCallback,
    options,
    setFallbackProviderContext,
    fallbackProviderContext,
  });

  const { reconnected, reconnecting } = useRoomReconnection(room);

  const [isSharingScreen, toggleScreenShare, stopScreenShareRef] = useScreenShareToggle({
    room,
    onError: handleScreenSharingError,
    onSuccess: handleScreenSharingSuccess,
  });
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(room, onError, isSharingScreen, toggleScreenShare);
  useHandleTrackPublicationFailed(room, onError);
  useRestartTrackOnDeviceChange(localTracks);
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle({
    room,
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    onError: handleVideoToggleError,
  });
  const localAudioTrack = find(localTracks, (track) => track && track.kind === 'audio');
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle({
    localAudioTrack,
    onError: handleAudioToggleError,
  });
  const srcMediaStreamTrack = localAudioTrack?.noiseCancellation?.sourceTrack;
  const mediaStreamTrackObj = useMediaStreamTrack(localAudioTrack);

  const localAudioInputGroupId =
    srcMediaStreamTrack?.getSettings()?.groupId || mediaStreamTrackObj?.getSettings()?.groupId;
  const value = useMemo(
    () => ({
      room,
      localTracks,
      mediaStream,
      isConnecting,
      onError: onErrorCallback,
      getLocalVideoTrack,
      connect,
      reconnected,
      reconnecting,
      isLocalTracksAcquired,
      removeLocalVideoTrack,
      isSharingScreen,
      toggleScreenShare,
      getAudioAndVideoTracks,
      getAudioVideoStream,
      isVideoEnabled,
      toggleVideoEnabled,
      isAudioEnabled,
      toggleAudioEnabled,
      activeSinkId,
      setActiveSinkId,
      stopScreenShareRef,
      localAudioInputGroupId,
      fallbackProviderContext,
      setFallbackProviderContext,
      browserPermissions,
      localConnectedTracks,
      tracksAcquiringError,
      videoView,
      setVideoView,
    }),
    [
      room,
      localTracks,
      mediaStream,
      isConnecting,
      getLocalVideoTrack,
      connect,
      reconnected,
      reconnecting,
      isLocalTracksAcquired,
      removeLocalVideoTrack,
      isSharingScreen,
      toggleScreenShare,
      getAudioAndVideoTracks,
      getAudioVideoStream,
      isVideoEnabled,
      toggleVideoEnabled,
      isAudioEnabled,
      toggleAudioEnabled,
      activeSinkId,
      setActiveSinkId,
      stopScreenShareRef,
      localAudioInputGroupId,
      fallbackProviderContext,
      setFallbackProviderContext,
      browserPermissions,
      localConnectedTracks,
      tracksAcquiringError,
      videoView,
      setVideoView,
    ],
  );
  return (
    <VideoContext.Provider value={value}>
      <SelectedParticipantProvider room={room}>
        {/*
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider */}
        {children}
      </SelectedParticipantProvider>
      <AttachVisibilityHandler room={room} isVideoEnabled={isVideoEnabled} toggleVideoEnabled={toggleVideoEnabled} />
    </VideoContext.Provider>
  );
}
export default VideoProvider;
