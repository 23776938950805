/* eslint-disable no-console,import/no-import-module-exports */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
// import '@babel/polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';
import { Workbox } from 'workbox-window';

import 'sanitize.css/sanitize.css';
import 'draft-js/dist/Draft.css';
import 'draftail/dist/draftail.css';

import App from 'containers/App';
import LanguageProvider from 'containers/LanguageProvider';
import { DarkModeProvider } from 'utils/themeContext';
import initSentry from 'utils/sentry';

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico';

/* eslint-enable import/no-unresolved, import/extensions */

import configureStore from './configureStore';

// Import i18n messages
const { translationMessages } = require('./i18n');
initSentry();

// Create redux store with history
const initialState = {};

const store = configureStore(initialState);
const MOUNT_NODE = document.getElementById('app');

// eslint-disable-next-line no-console
ReactGA.initialize('UA-194793975-2');
const root = createRoot(MOUNT_NODE);
const render = (messages) => {
  root.render(
    <DarkModeProvider>
      <Provider store={store}>
        <LanguageProvider messages={messages}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LanguageProvider>
      </Provider>
    </DarkModeProvider>,
  );
};

// if (module.hot) {
//   // Hot reloadable React components and translation json files
//   // modules.hot.accept does not accept dynamic dependencies,
//   // have to be constants at compile-time
//   module.hot.accept(['./i18n', 'containers/App'], () => {
//     // ReactDOM.unmountComponentAtNode(MOUNT_NODE);
//     root.unmount();
//     render(translationMessages);
//   });
// }

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  // eslint-disable-next-line no-promise-executor-return
  new Promise((resolve) => resolve(import('intl')))
    // eslint-disable-next-line import/extensions
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not the most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  const wb = new Workbox('/service-worker.js');
  // let registration;
  const showSkipWaitingPrompt = (event) => {
    if (event.isUpdate) {
      // Notification({
      //   title: 'Update available!',
      //   message: 'A new version is available, click to update',
      //   duration: 0,
      //   onClick: async () => {
      //     if (registration && registration.waiting) {
      //       await messageSW(registration.waiting, { type: 'SKIP_WAITING' });
      //     }
      //     window.location.reload();
      //   },
      // });
    }
  };
  wb.addEventListener('waiting', showSkipWaitingPrompt);
  wb.addEventListener('externalwaiting', showSkipWaitingPrompt);

  wb.addEventListener('installed', showSkipWaitingPrompt);

  // eslint-disable-next-line no-return-assign
  // wb.register().then(r => (registration = r));
}
