import * as Sentry from '@sentry/react';
import { set, get, unset } from 'lodash';

const load = () => {
  let state = {};
  try {
    state = JSON.parse(localStorage.getItem('tv.cp.state') || '{}');
  } catch (e) {
    Sentry.captureException(e);
  }
  return state;
};
const clear = () => {
  localStorage.setItem('tv.cp.state', null);
};

const setItem = (key, value) => {
  const state = load();
  set(state, `${key}`, value);
  localStorage.setItem('tv.cp.state', JSON.stringify(state));
  return null;
};

const getItem = (key) => {
  const state = load();
  return get(state, `${key}`);
};

const removeItem = (key) => {
  const state = load();
  unset(state, key); // Safely remove the specified key, including nested keys
  localStorage.setItem('tv.cp.state', JSON.stringify(state));
};

export { load as loadState, setItem, getItem, clear as clearState, removeItem };
