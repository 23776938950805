import { createSelector } from 'reselect';
import { initialState } from 'containers/SecondaryCamera/reducer';

const selectSecondaryCamera = (state) => state?.secondaryCamera || initialState;

const selectSecondaryCameraState = createSelector(selectSecondaryCamera, (subState) => subState);
const secondaryCameraVideoServiceProviderState = createSelector(
  selectSecondaryCamera,
  (subState) => subState?.roomDetails?.videoServiceProvider,
);
const participantDetailsState = createSelector(selectSecondaryCamera, (subState) => subState?.participantData);

export default selectSecondaryCamera;
export { selectSecondaryCameraState, secondaryCameraVideoServiceProviderState, participantDetailsState };
